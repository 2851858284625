import { atom, selector } from "recoil";

export interface ICompanyState {
    id?: number;
    legalName?: string,
    inn?: string,
    kpp?: string,
    website?: string,
    address?: string,
    businessArea?: string,
    companyForm?: string,
}

export const companyState = atom<ICompanyState>({
    key: "companyState",
    default: {
    }
});

export const getCompanyId = selector<undefined | number>({
    key: "companyId",
    get: ({ get }) => {
        return get(companyState).id;
    }
});

export const getCompanyLegalName = selector<undefined | string>({
    key: "companyLegalName",
    get: ({ get }) => {
        return get(companyState).legalName;
    }
});

export const getCompanyInn = selector<undefined | string>({
    key: "companyInn",
    get: ({ get }) => {
        return get(companyState).inn;
    }
});

export const getCompanyKpp = selector<undefined | string>({
    key: "companyKpp",
    get: ({ get }) => {
        return get(companyState).kpp;
    }
});

export const getCompanyWebsite = selector<undefined | string>({
    key: "companyWebsite",
    get: ({ get }) => {
        return get(companyState).website;
    }
});

export const getCompanyAddress = selector<undefined | string>({
    key: "companyAddress",
    get: ({ get }) => {
        return get(companyState).address;
    }
});

export const getCompanyBusinessArea = selector<undefined | string>({
    key: "companyBusinessArea",
    get: ({ get }) => {
        return get(companyState).businessArea;
    }
});