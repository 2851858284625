import React from "react";
import { ISvgIconProps } from "./types/SvgIcon.types";

const RadioUncheckedIcon = (props: ISvgIconProps) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="7.5" fill="#EFF4F6" stroke="#E2E8EC"/>
        </svg>
    );
};

export default RadioUncheckedIcon;