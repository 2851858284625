import React from 'react';
import { ReactComponent as LogoIcon } from '../../assets/img/logo.svg';
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getIsProfileModalOpened, modalState, ModalType } from "../../store/Modal.recoil";

import WalletFilledIcon from "../UI/SvgIcons/WalletFilledIcon";
import ProfileIcon from "../UI/SvgIcons/ProfileIcon";

import './styles/Header.sass';
import { prettifyMoneyValue } from "../../helpers/prettifier.helpers";
import BurgerIcon from "../UI/SvgIcons/BurgerIcon";
import { IHeaderProps } from "./types/Header.props";
import { getUserBalance, userState } from "../../store/User.recoil";
import { companyState, getCompanyLegalName } from "../../store/Company.recoil";
import { profileCompanyFactory, profilePersonFactory, profileState } from "../../store/Profile.recoil";
import { useNavigate } from "react-router-dom";
import { clientsState, getClientsIsLoggedToClient } from "../../store/Clients.recoil";

const Header = ( props: IHeaderProps) => {
    const { onBurgerButtonToggle } = props;

    const setModalState = useSetRecoilState(modalState);
    const setClientsState = useSetRecoilState(clientsState);
    const setProfileState = useSetRecoilState(profileState);

    const user = useRecoilValue(userState);
    const company = useRecoilValue(companyState);
    const isProfileModalOpened = useRecoilValue(getIsProfileModalOpened);
    const userBalance = useRecoilValue(getUserBalance);
    const companyLegalName = useRecoilValue(getCompanyLegalName);
    const isLoggedToClient = useRecoilValue(getClientsIsLoggedToClient);

    const onProfileModalToggle = () => {
        setModalState({
            type: ModalType.profile,
            isOpened: !isProfileModalOpened,
        })
    }

    const navigate = useNavigate();
    const onFinancesButtonToggle = () => {
        if (isLoggedToClient) {
            setProfileState((state) => {
                return {
                    ...state,
                    person: profilePersonFactory(user),
                    company: profileCompanyFactory(company),
                }
            });
            setClientsState((state) => {
                return {
                    ...state,
                    currentClient: undefined
                }
            })
        }
        navigate('/finances');
    }

    return (
        <div className="header">
            <a className="header__logo" href="/clients">
                <LogoIcon/>
            </a>

            <div className="header__nav">
                <button className="header__finances" onClick={onFinancesButtonToggle}>
                    <WalletFilledIcon />
                    <div
                        className="header__balance"
                        dangerouslySetInnerHTML={{__html: prettifyMoneyValue(userBalance) }}
                    ></div>
                </button>
                <button className="header__profile" type="button" onClick={onProfileModalToggle}>
                    <ProfileIcon />
                    {companyLegalName}
                </button>
            </div>

            <button className="header__burger" onClick={onBurgerButtonToggle}>
                <BurgerIcon />
            </button>
        </div>
    )
}

export default Header;