import React, { useEffect, useState } from 'react';
import "../styles/Page.sass";
import "./styles/CampaignViewPage.sass";
import PageTop from "../../../components/PageTop/PageTop";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import { setDocumentTitle } from "../../../helpers/document.helpers";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../../api";
import Button from "../../../components/UI/Button/Button";
import CampaignView from "../../../components/Campaign/CampaignView/CampaignView";
import GoBackIcon from "../../../components/UI/SvgIcons/GoBackIcon";
import { ICampaign } from "../../../api/services/campaigns/campaigns.response";

const CampaignViewPage = () => {
    const navigate = useNavigate();
    const { id: campaignId } = useParams();
    const [campaign, setCampaign] = useState<ICampaign | undefined>();

    const onGoBackButtonClick = (): void => {
        return navigate('/campaigns');
    }

    const onEditButtonClick = (): void => {
        return navigate(`/campaigns/${campaignId}/edit`);
    }

    useEffect(() => {
        setDocumentTitle('Создание рекламной кампании');
    }, []);

    useEffect(() => {
        if (campaignId) {
            API.campaigns.view(campaignId)
                .then(( { data } ) => {
                    setCampaign(data);
                })
        }
    }, [campaignId]);

    return (
        <div className="page campaign-view-page">
            {campaign && (
                <>
                    <PageTop className="campaign-view-page__top">
                        <button className="campaign-view-page__back" onClick={onGoBackButtonClick}>
                            <GoBackIcon/>
                        </button>

                        <PageTitle
                            className="campaign-view-page__title"
                            title={campaign.name}
                        />

                        <div className="campaign-view-page__actions">
                            <Button title="Редактировать" onClick={onEditButtonClick}/>
                            <Button isWhite title="Остановить"/>
                        </div>
                    </PageTop>

                    <CampaignView campaign={campaign}/>
                </>
            )}
        </div>
    );
}

export default CampaignViewPage;
