import React, { useEffect, useState } from 'react';

import './App.sass';

import { Outlet } from "react-router-dom";

import Header from "components/Header/Header";
import Sidebar from "components/Sidebar/Sidebar";
import ProfileSidebar from "components/Profile/ProfileSidebar/ProfileSidebar";
import FinancesAddModal from "./components/Finances/FinancesAddModal/FinancesAddModal";
import ClientsCreateModal from "./components/Client/ClientsCreateModal/ClientsCreateModal";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    getIsProfileModalOpened,
    getIsFinancesAddModalOpened,
    getIsFinancesAddAmountModalOpened,
    getIsCreateClientModalOpened,
    getIsDeleteClientModalOpened,
    getIsReleaseClientModalOpened,
    getIsTransferClientModalOpened,
    getIsCampaignAdvertiseMarkModalOpened,
    getIsSupportModalOpened, getIsFinancesRedistributionModalOpened,
} from "./store/Modal.recoil";
import { companyState } from "./store/Company.recoil";
import { toastState } from "./store/Toast.recoil";
import Toast from "./components/UI/Toast/Toast";
import ClientsDeleteModal from "./components/Client/ClientsDeleteModal/ClientsDeleteModal";
import ClientsReleaseModal from "./components/Client/ClientsReleaseModal/ClientsReleaseModal";
import ClientsTransferModal from "./components/Client/ClientsTransferModal/ClientsTransferModal";
import { userState } from "./store/User.recoil";
import CampaignAdvertiserMarkModal from "./components/Campaign/CampaignAdvertiserMarkModal/CampaignAdvertiserMarkModal";
import SupportModal from "./components/SupportModal/SupportModal";
import { profileCompanyFactory, profilePersonFactory, profileState } from "./store/Profile.recoil";
import API from "./api";
import FinancesAddAmountModal from "./components/Finances/FinancesAddAmountModal/FinancesAddAmountModal";
import FinancesRedistributionModal from "./components/Finances/FinancesRedistributionModal/FinancesRedistributionModal";
import MobileMenu from "./components/MobileMenu/MobileMenu";
import classNames from "classnames";
import { IDictionaryItem } from "./models/types";
import { clientsState, getClientsCurrentClient } from "./store/Clients.recoil";
import { campaignsState } from "./store/Campaigns.recoil";
import { DEFAULT_LIST_ITEMS_COUNT } from "./models/consts";
import { IApiClientsListResponse } from "./api/services/clients/clients.response";

const mapDataToListItem = ( data: object ): IDictionaryItem[] => {
    const isArray = Array.isArray(data);
    return Object.entries(data).map(( [key, value] ) => {
        return {
            label: String(value),
            value: isArray ? Number(key) : String(key),
        };
    });
}

const App = () => {
    const currentClient = useRecoilValue(getClientsCurrentClient);

    const isProfileModalOpened = useRecoilValue(getIsProfileModalOpened);
    const isFinancesAddModalOpened = useRecoilValue(getIsFinancesAddModalOpened);
    const isFinancesAddAmountModalOpened = useRecoilValue(getIsFinancesAddAmountModalOpened);
    const isFinancesRedistributionModalOpened = useRecoilValue(getIsFinancesRedistributionModalOpened);
    const isCreateClientModalOpened = useRecoilValue(getIsCreateClientModalOpened);
    const isDeleteClientModalOpened = useRecoilValue(getIsDeleteClientModalOpened);
    const isReleaseClientModalOpened = useRecoilValue(getIsReleaseClientModalOpened);
    const isTransferClientModalOpened = useRecoilValue(getIsTransferClientModalOpened);
    const isCampaignAdvertiseMarkModalOpened = useRecoilValue(getIsCampaignAdvertiseMarkModalOpened);
    const isSupportModalOpened = useRecoilValue(getIsSupportModalOpened);

    const toast = useRecoilValue(toastState);

    const setUserState = useSetRecoilState(userState);
    const setCompanyState = useSetRecoilState(companyState);
    const setProfileState = useSetRecoilState(profileState);
    const setCampaignsState = useSetRecoilState(campaignsState);
    const setClientsState = useSetRecoilState(clientsState);
    const setCampaignsTargetingState = ( dictionary: object ): void => {
        setCampaignsState(( state ) => {
            const { targeting } = state;

            return {
                ...state,
                targeting: {
                    ...targeting,
                    ...dictionary
                }
            }
        })
    };


    const [isMobileMenuOpened, setIsMobileMenuOpened] = useState<boolean>(false);

    useEffect(() => {
        Promise.all([
            API.users.info(),
            API.companies.info()
        ]).then(( [userResponse, companyResponse] ) => {
            const { data: user } = userResponse;
            const { data: company } = companyResponse;

            setUserState(user);
            setCompanyState(company);

            setProfileState(( state ) => {
                return {
                    ...state,
                    person: profilePersonFactory(user),
                    company: profileCompanyFactory(company),
                }
            });
        });

        API.campaigns.localities()
            .then(( { data } ) => {
                setCampaignsTargetingState({
                    localities: data
                })
            });

        API.campaigns.intervals()
            .then(( { data } ) => {
                setCampaignsTargetingState({
                    intervals: mapDataToListItem(data)
                })
            });

        API.campaigns.genres()
            .then(( { data } ) => {
                setCampaignsTargetingState({
                    genres: mapDataToListItem(data)
                })
            });

        API.campaigns.devices()
            .then(( { data } ) => {
                setCampaignsTargetingState({
                    devices: mapDataToListItem(data)
                })
            });

        API.campaigns.ages()
            .then(( { data } ) => {
                setCampaignsTargetingState({
                    ages: mapDataToListItem(data)
                })
            });

        API.campaigns.genders()
            .then(( { data } ) => {
                setCampaignsTargetingState({
                    genders: mapDataToListItem(data)
                })
            })

        API.clients.list()
            .then(( response: IApiClientsListResponse ) => {
                const { count, limit, next, page, prev, results } = response;

                setClientsState((state) => ({
                    ...state,
                    list: {
                        prev,
                        next,
                        count,
                        limit,
                        page,
                        items: results
                    }
                }))
            })

    }, [setUserState, setCompanyState, setProfileState, setClientsState, setCampaignsTargetingState]);

    useEffect(() => {
        API.campaigns.list(currentClient?.id)
            .then(( response ) => {
                const { count, limit, next, page, prev, results } = response;

                setCampaignsState((state) => ({
                    ...state,
                    list: {
                        prev,
                        next,
                        count,
                        page,
                        limit,
                        items: results,
                    }
                }))

            })
            .catch(( error ) => {
                console.log(error);
            })
    }, [currentClient, setCampaignsState]);

    const onBurgerButtonToggle = (): void => {
        setIsMobileMenuOpened(!isMobileMenuOpened);
    }

    return (
        <div className={classNames("app", { "is-mobile-menu-opened": isMobileMenuOpened })}>
            <Header onBurgerButtonToggle={onBurgerButtonToggle}/>

            <MobileMenu/>

            <div className="main">
                <Sidebar/>

                <div className="content">
                    <Outlet/>
                </div>
            </div>

            {isProfileModalOpened && (<ProfileSidebar/>)}
            {isFinancesAddModalOpened && (<FinancesAddModal/>)}
            {isFinancesAddAmountModalOpened && (<FinancesAddAmountModal/>)}
            {isFinancesRedistributionModalOpened && (<FinancesRedistributionModal/>)}
            {isCreateClientModalOpened && (<ClientsCreateModal/>)}
            {isDeleteClientModalOpened && (<ClientsDeleteModal/>)}
            {isReleaseClientModalOpened && (<ClientsReleaseModal/>)}
            {isTransferClientModalOpened && (<ClientsTransferModal/>)}
            {isCampaignAdvertiseMarkModalOpened && (<CampaignAdvertiserMarkModal/>)}
            {isSupportModalOpened && (<SupportModal/>)}


            {toast.isShown && (<Toast type={toast.type} message={toast.message}/>)}
        </div>
    );
}

export default App;
