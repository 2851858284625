import React, { useEffect } from 'react';
import "../styles/Page.sass";
import PageTop from "../../../components/PageTop/PageTop";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import ClientsFilters from "../../../components/Client/ClientsFilters/ClientsFilters";
import { setDocumentTitle } from "../../../helpers/document.helpers";

const StatisticsPage = () => {
    useEffect(() => {
        setDocumentTitle('Статистика');
    }, []);

    return (
        <div className="page clients">
            <PageTop className="clients__top">
                <PageTitle
                    className="clients__title"
                    title="Статистика"
                />

                {false && (<ClientsFilters />)}
            </PageTop>
        </div>
    );
}

export default StatisticsPage;
