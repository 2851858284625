import React, { useCallback, useEffect } from "react";
import "./styles/Toast.sass";
import { IToastProps, ToastType } from "./types/Toast.types";
import classNames from "classnames";
import SuccessIcon from "../SvgIcons/SuccessIcon";
import ErrorIcon from "../SvgIcons/ErrorIcon";
import { useRecoilState, useRecoilValue } from "recoil";
import { getIsToastShown, toastState } from "../../../store/Toast.recoil";

const Toast = (props: IToastProps) => {
    const { type, message, className } = props;
    const isToastShown = useRecoilValue(getIsToastShown);
    const [toast, setToast] = useRecoilState(toastState);

    const closeToast = useCallback(() => {
        setToast({
            ...toast,
            isShown: false
        })
    }, [toast, setToast]);

    const onClick = (e: React.MouseEvent) => {
        closeToast();
    }

    useEffect(() => {
        setTimeout(() => {
            closeToast()
        }, 3000)
    }, [isToastShown, closeToast]);

    return (
        <div className={classNames(
            'toast',
            className,
            {'error': type === ToastType.ERROR },
            {'success': type === ToastType.SUCCESS },
        )} onClick={onClick}>
            <div className="toast__icon">
                {type === ToastType.SUCCESS && <SuccessIcon />}
                {type === ToastType.ERROR && <ErrorIcon />}
            </div>
            <div className="toast__message">{message}</div>
        </div>
    );
};

export default Toast;