import axios from 'axios';
import { IApiPaymentsCreateRequest } from "./payments.requests";

class PaymentsApi {
    list = async () => {
        const { data } = await axios.get(`/payments/list`);

        return data;
    }

    create = async (params?: IApiPaymentsCreateRequest) => {
        const { data } = await axios.post(`/payments/create`, params);

        return data;
    }
}

const paymentsAPI = new PaymentsApi();
export default paymentsAPI;