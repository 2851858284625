import React, { Suspense, useEffect } from 'react';
import "../styles/Page.sass";
import PageTop from "../../../components/PageTop/PageTop";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import ClientsFilters from "../../../components/Client/ClientsFilters/ClientsFilters";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getIsCreateClientModalOpened, modalState, ModalType } from "../../../store/Modal.recoil";
import ClientsList from "../../../components/Client/ClientsList/ClientsList";
import { setDocumentTitle } from "../../../helpers/document.helpers";
import ClientsPlates from "../../../components/Client/ClientsPlates/ClientsPlates";
import { getClientsList } from "../../../store/Clients.recoil";
import ClientsListEmpty from "../../../components/Client/ClientsList/components/ClientsListEmpty/ClientsListEmpty";

const ClientsPage = () => {
    const clientsList = useRecoilValue(getClientsList);

    useEffect(() => {
        setDocumentTitle('Клиенты');
    }, []);

    const setModalState = useSetRecoilState(modalState);
    const isCreateClientModalOpened = useRecoilValue(getIsCreateClientModalOpened);

    const onButtonClick = () => {
        setModalState({
            type: ModalType.createClient,
            isOpened: !isCreateClientModalOpened,
        })
    }

    return (
        <div className="page clients">
            <PageTop className="clients__top">
                <PageTitle
                    className="clients__title"
                    title="Клиенты"
                    hasButton
                    buttonTitle="Создать кабинет клиента"
                    buttonAction={onButtonClick}
                />

                {false && (<ClientsFilters/>)}
            </PageTop>

            <Suspense>
                {clientsList.items.length === 0 && (<ClientsListEmpty />)}
                {clientsList.items.length > 0 && (
                    <>
                        <ClientsList list={clientsList} />
                        <ClientsPlates list={clientsList} />
                    </>
                )}
            </Suspense>
        </div>
    );
}

export default ClientsPage;
