import React from 'react';
import "./styles/ChipsItem.sass";
import classNames from "classnames";
import { IChipsItemProps } from "./types/ChipsItem.types";

const ChipsItem = ( props: IChipsItemProps ) => {
    const { item, isSelected, className, onClick } = props;

    return (
        <div className={classNames("chips-item", className, { "is-selected": isSelected })} onClick={onClick}>
            {item.label}
        </div>
    );
}

export default ChipsItem;