import React, { useEffect, useState } from 'react';
import "./styles/CampaignFormTabBudget.sass";
import TextInput from "../../../../UI/Inputs/TextInput/TextInput";
import classNames from "classnames";
import RadioUncheckedIcon from "../../../../UI/SvgIcons/RadioUncheckedIcon";
import RadioCheckedIcon from "../../../../UI/SvgIcons/RadioCheckedIcon";
import { ICampaignFormBudgetData, ICampaignFormTabBudgetProps } from "./types/CampaignFormTabBudget.types";
import { LimitType } from "../../types/CampaignForm.types";

const MIN_CPM = 0.25;

const CampaignFormTabBudget = ( props: ICampaignFormTabBudgetProps ) => {
    const { values: formValues, errors: formErrors, onTabDataChanged } = props;

    const [limitType, setLimitType] = useState<LimitType>(LimitType.AsBudget);

    const onLimitTypeButtonClick = ( limitType: LimitType ) => {
        setLimitType(limitType);
    }

    const [formData, setFormData] = useState<ICampaignFormBudgetData>({
        cost: MIN_CPM,
        dailyImpressionsPerPerson: 0,
        maxImpressionsPerDay: 0,
        maxImpressions: 0,
        budgetDaily: 0,
        budgetTotal: 0,
    });

    const onBudgetParamChanged = ( e: React.ChangeEvent<HTMLInputElement> ) => {
        const value = e.target.value;
        const name = e.target.name as keyof ICampaignFormBudgetData;

        formData[name] = Number(value);

        if (limitType === LimitType.AsBudget) {
            formData.maxImpressionsPerDay = formData.budgetDaily / formData.cost;
            formData.maxImpressions = formData.budgetTotal / formData.cost;
        } else {
            formData.budgetDaily = formData.maxImpressionsPerDay * formData.cost;
            formData.budgetTotal = formData.maxImpressions * formData.cost;
        }

        setFormData({ ...formData });

        const { cost, dailyImpressionsPerPerson, maxImpressionsPerDay, maxImpressions, budgetDaily, budgetTotal } = formData;

        if (typeof onTabDataChanged === "function") {
            onTabDataChanged({
                cost,
                dailyImpressionsPerPerson,
                maxImpressionsPerDay,
                maxImpressions,
                budgetDaily,
                budgetTotal
            });
        }
    }

    useEffect(() => {
        setFormData({
            cost: formValues.cost,
            dailyImpressionsPerPerson: formValues.dailyImpressionsPerPerson,
            maxImpressionsPerDay: formValues.maxImpressionsPerDay,
            maxImpressions: formValues.maxImpressions,
            budgetDaily: formValues.budgetDaily,
            budgetTotal: formValues.budgetTotal,
        });
    }, [formValues]);

    return (
        <div className="campaign-create-form__fields">
            <p className="campaign-create-form__text">
                Для контроля бюджетов по кампании вы можете задать допустимый лимит на всю кампанию, а также на день.
            </p>

            <div className="campaign-create-form__budget">
                <div className={classNames(
                    "campaign-create-form__limit",
                    "limit",
                    { "is-selected": limitType === LimitType.AsBudget }
                )}
                     onClick={( e ) => onLimitTypeButtonClick(LimitType.AsBudget)}
                >
                    <div className="limit__label">
                        {limitType !== LimitType.AsBudget && (<RadioUncheckedIcon/>)}
                        {limitType === LimitType.AsBudget && (<RadioCheckedIcon/>)}
                        По бюджету
                    </div>
                    <div className="limit__badge">Рекомендовано</div>
                    <div className="limit__hint">Укажите бюджет и ставку, а мы покажем вам количество показов рекламы
                        пользователям
                    </div>
                </div>
                <div className={classNames(
                    "campaign-create-form__limit",
                    "limit",
                    { "is-selected": limitType === LimitType.AsLimit }
                )}
                     onClick={( e ) => onLimitTypeButtonClick(LimitType.AsLimit)}
                >
                    <div className="limit__label">
                        {limitType !== LimitType.AsLimit && (<RadioUncheckedIcon/>)}
                        {limitType === LimitType.AsLimit && (<RadioCheckedIcon/>)}
                        По показам
                    </div>
                    <span className="limit__hint">Укажите количество показов и ставку, а мы рассчитаем бюджет на рекламную кампанию</span>
                </div>
            </div>

            <div className="campaign-create-form__calculator calculator">
                <div className="calculator__column">
                    {limitType === LimitType.AsBudget && (
                        <>
                            <TextInput
                                label="Бюджет в сутки"
                                name="budgetDaily"
                                onChange={onBudgetParamChanged}
                                value={formData.budgetDaily}
                                hasError={!!formErrors?.budgetDaily}
                                errorMessage={formErrors?.budgetDaily}
                            />

                            <TextInput
                                label="Бюджет на кампанию"
                                name="budgetTotal"
                                onChange={onBudgetParamChanged}
                                value={formData.budgetTotal}
                                hasError={!!formErrors?.budgetTotal}
                                errorMessage={formErrors?.budgetTotal}
                            />
                        </>
                    )}

                    {limitType === LimitType.AsLimit && (
                        <>
                            <TextInput
                                label="Показов в сутки (на аудиторию)"
                                name="maxImpressionsPerDay"
                                onChange={onBudgetParamChanged}
                                value={formData.maxImpressionsPerDay}
                            />
                            <TextInput
                                label="Показы всего (на кампанию)"
                                name="maxImpressions"
                                onChange={onBudgetParamChanged}
                                value={formData.maxImpressions}
                            />
                        </>
                    )}

                    <div className="campaign-create-form__field">
                        <TextInput
                            label="Ставка за показ"
                            name="cost"
                            onChange={onBudgetParamChanged}
                            value={formData.cost}
                        />

                        <div className="campaign-create-form__hint">
                            <div>Минимальная ставка - {MIN_CPM} ₽.</div>
                            <div>Рекомендованная ставка - {MIN_CPM} ₽.</div>
                        </div>
                    </div>

                    <TextInput
                        label="Показов в сутки (на посетителя)"
                        name="dailyImpressionsPerPerson"
                        onChange={onBudgetParamChanged}
                        value={formData.dailyImpressionsPerPerson}
                        hasError={!!formErrors?.dailyImpressionsPerPerson}
                        errorMessage={formErrors?.dailyImpressionsPerPerson}
                    />
                </div>
                <div className="calculator__column">
                    {limitType === LimitType.AsBudget && (
                        <>
                            <div className="calculator__total">
                                <div className="total__label">Показов в сутки (на аудиторию)</div>
                                <div className="total__amount">{formData.maxImpressionsPerDay.toLocaleString()}</div>
                            </div>

                            <div className="calculator__total">
                                <div className="total__label">Показы всего (на кампанию)</div>
                                <div className="total__amount">{formData.maxImpressions.toLocaleString()}</div>
                            </div>
                        </>
                    )}

                    {limitType === LimitType.AsLimit && (
                        <>
                            <div className="calculator__total">
                                <div className="total__label">Бюджет в сутки</div>
                                <div className="total__amount">{formData.budgetDaily.toLocaleString()}</div>
                            </div>

                            <div className="calculator__total">
                                <div className="total__label">Бюджет всего</div>
                                <div className="total__amount">{formData.budgetTotal.toLocaleString()}</div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CampaignFormTabBudget;