import { HTMLAttributes } from "react";

export enum CheckboxState {
    CHECKED = "checked",
    PARTIAL_CHECKED = "partialChecked",
    UNCHECKED = "unchecked",
}

export interface ICheckboxProps extends HTMLAttributes<HTMLDivElement> {
    label?: string;
    state?: CheckboxState;
    onClick?: () => void;
}