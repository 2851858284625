import React, { useState } from 'react';
import "./styles/FinancesRedistributionModal.sass";
import Button from "../../UI/Button/Button";
import TextInput from "../../UI/Inputs/TextInput/TextInput";
import Modal from "../../UI/Modal/Modal";
import API from "../../../api";
import SearchInput from "../../UI/Inputs/SearchInput/SearchInput";
import {
    IFinancesRedistributionFormData,
    IFinancesRedistributionFormErrors
} from "./types/FinancesRedistributionModal.types";
import { useSetRecoilState } from "recoil";
import { modalState } from "../../../store/Modal.recoil";

const FinancesRedistributionModal = () => {
    const setModalState = useSetRecoilState(modalState);
    const [formData, setFormData] = useState<IFinancesRedistributionFormData>({
        amount: 0
    });
    const [formErrors, setFormErrors] = useState<IFinancesRedistributionFormErrors>({});

    const onValueChanged = ( e: any ) => {
        const newFormData = {
            ...formData,
            [e.target.name]: e.target.value
        }

        setFormData(newFormData);
    }

    const renderCompanySuggestion = ( item: any ): string => {
        return item.name + ', ИНН: ' + item.inn;
    }

    const selectCompanySuggestion = ( item: any ) => {
        const newFormData = {
            ...formData,
            companyId: item.id,
            companyName: item.name
        }

        setFormData(newFormData);
    }

    const isFormDataValid = (): boolean => {
        let errors: IFinancesRedistributionFormErrors = {};

        if (!formData.companyId) {
            errors.companyId = "Необходимо выбрать компанию для зачисления средств.";
        }

        if (formData.amount <= 0) {
            errors.amount = "Значение «Сумма» должно быть больше 0.";
        }
        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    }

    const onCreateButtonClick = () => {
        if (!isFormDataValid()) return;

        setModalState({
            type: null,
            isOpened: false
        })
    }

    return (
        <Modal justifyContent="center">
            <div className="finances-redistribution-modal">
                <div className="finances-redistribution-modal__header">
                    <div className="finances-redistribution-modal__title">Распределение бюджета</div>
                </div>
                <div className="finances-redistribution-modal__body">
                    <SearchInput
                        isRequired
                        name="legalName"
                        label="Название компании"
                        placeholder="Введите название компании"
                        value={formData.companyName}
                        hasError={!!formErrors?.companyId}
                        errorMessage={formErrors?.companyId}
                        suggestionApi={API.clients.suggestions}
                        suggestionRenderResult={renderCompanySuggestion}
                        suggestionClickResult={selectCompanySuggestion}
                    />

                    <TextInput
                        isRequired
                        className="finances-redistribution-modal__input"
                        label="Сумма"
                        placeholder="Введите сумму для перевода"
                        name="amount"
                        value={formData.amount}
                        hasError={!!formErrors?.amount}
                        errorMessage={formErrors?.amount}
                        onChange={onValueChanged}
                    />

                    <Button
                        className="finances-redistribution-modal__button"
                        title="Пополнить"
                        onClick={onCreateButtonClick}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default FinancesRedistributionModal;
