import React, { Suspense, useEffect, useState } from 'react';
import "../styles/Page.sass";
import PageTop from "../../../components/PageTop/PageTop";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import ClientsFilters from "../../../components/Client/ClientsFilters/ClientsFilters";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getIsCreateClientModalOpened, modalState, ModalType } from "../../../store/Modal.recoil";
import ClientsList from "../../../components/Client/ClientsList/ClientsList";
import { setDocumentTitle } from "../../../helpers/document.helpers";
import { IGridCollection } from "../../../components/UI/Grid/types/Grid.types";
import API from "../../../api";
import { IApiClientsListItem, IApiClientsListResponse } from "../../../api/services/clients/clients.response";
import ClientsPlates from "../../../components/Client/ClientsPlates/ClientsPlates";

const ClientsPage = () => {
    const [plates, setPlates] = useState<IApiClientsListItem[]>([]);
    const [grid, setGrid] = useState<IGridCollection>({
        count: 25,
        page: 1,
        limit: 25,
        items: []
    });
    const [isFetching, setIsFetching] = useState<boolean>(false);

    useEffect(() => {
        setDocumentTitle('Клиенты');
        setIsFetching(true);

        API.clients.list()
            .then(( response: IApiClientsListResponse ) => {
                const { results, count, page, limit } = response;

                setPlates(results);
                setGrid({
                    count,
                    page,
                    limit,
                    items: results
                })
                setIsFetching(false);
            })
    }, []);

    const setModalState = useSetRecoilState(modalState);
    const isCreateClientModalOpened = useRecoilValue(getIsCreateClientModalOpened);

    const onButtonClick = () => {
        setModalState({
            type: ModalType.createClient,
            isOpened: !isCreateClientModalOpened,
        })
    }

    return (
        <div className="page clients">
            <PageTop className="clients__top">
                <PageTitle
                    className="clients__title"
                    title="Клиенты"
                    hasButton
                    buttonTitle="Создать кабинет клиента"
                    buttonAction={onButtonClick}
                />

                {false && (<ClientsFilters/>)}
            </PageTop>

            <Suspense>
                <ClientsList
                    items={grid.items}
                    page={grid.page}
                    limit={grid.limit}
                    count={grid.count}
                    isFetching={isFetching}
                />

                <ClientsPlates plates={plates} />
            </Suspense>
        </div>
    );
}

export default ClientsPage;
