import React from 'react';
import './styles/CampaignsList.sass';
import { ICampaignsListProps } from "./types/CampaignsList.types";
import CampaignsListItem from "../CampaignsListItem/CampaignsListItem";
import classNames from "classnames";
import CampaignsListEmpty from "./components/CampaignsListEmpty/CampaignsListEmpty";
import { ICampaignsListItem } from "../../../store/Campaigns.recoil";

const CampaignsList = (props: ICampaignsListProps) => {
    const { items } = props;

    return (
        <div className={classNames("campaigns-list", { "is-empty": items.length === 0 })}>
            {items.length === 0 && (<CampaignsListEmpty />)}
            {items.map((item: ICampaignsListItem, i: number) => (
                <CampaignsListItem key={i} item={item} />
            ))}
        </div>
    )
}

export default CampaignsList;