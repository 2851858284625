import React from "react";
import { ISvgIconProps } from "./types/SvgIcon.types";

const CheckboxUncheckedIcon = (props: ISvgIconProps) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2.5" y="2.5" width="15" height="15" rx="2.5" stroke="#8D99A4"/>
        </svg>
    );
};

export default CheckboxUncheckedIcon;