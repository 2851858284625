import React, { useCallback } from 'react';
import "./styles/ClientsDeleteModal.sass";
import Modal from 'components/UI/Modal/Modal';
import Button from "../../UI/Button/Button";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getModalOptions, modalState } from "../../../store/Modal.recoil";
import { IClientsDeleteModalOptions } from "./types/ClientsDeleteModal.types";
import API from "../../../api";

const ClientsDeleteModal = () => {
    const setModal = useSetRecoilState(modalState);
    const modalOptions = useRecoilValue<IClientsDeleteModalOptions>(getModalOptions);
    const { client } = modalOptions;

    const closeModal = useCallback(() => {
        setModal({
            type: null,
            options: {},
            isOpened: false
        })
    }, [setModal]);

    const onCancelButtonClick = () => {
        closeModal();
    }

    const onDeleteButtonClick = () => {
        API.clients.delete(client.id)
            .then(() => {
                closeModal();
                window.location.reload();
            })
    }

    return (
        <Modal justifyContent="center" className="clients-delete-modal">
            <div className="clients-delete-modal__content">
                <div className="clients-create-modal__header">
                    <div className="clients-create-modal__title">Удалить клиента</div>
                </div>
                <div className="clients-delete-modal__body">
                    <div className="clients-delete-modal__text">
                        Вы действительно хотите удалить клиента <strong>{client.name}</strong>?
                    </div>
                    <div className="clients-delete-modal__hint">
                        Все данные клиента и рекламные кампании будут удалены
                    </div>
                </div>
                <div className="clients-delete-modal__footer">
                    <div className="clients-delete-modal__actions">
                        <Button isSecondary title="Отмена" onClick={onCancelButtonClick}/>
                        <Button title="Удалить" onClick={onDeleteButtonClick}/>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ClientsDeleteModal;