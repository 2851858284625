import React, { useEffect, useState } from 'react';
import "./styles/TargetingDevices.sass";
import { ITargetingDevicesProps } from "./types/TargetingDevices.types";
import classNames from "classnames";
import { DeviceType } from "../../../../types/CampaignForm.types";
import { toggleArrayItem } from "../../../../../../../helpers/array.helper";

const TargetingDevices = ( props: ITargetingDevicesProps ) => {
    const { label, selectedValues = [], onSelectedItemsChanged } = props;

    const [values, setValues] = useState<string[]>([]);

    const onClick = ( value: string ) => {
        const newValues = toggleArrayItem(values, value);
        setValues([...newValues]);

        if (typeof onSelectedItemsChanged === "function") {
            onSelectedItemsChanged([...newValues]);
        }
    }

    useEffect(() => {
        setValues(selectedValues);
    }, [selectedValues]);

    return (
        <div className="targeting-devices">
            {label && (<div className="targeting-devices__label">{label}</div>)}
            <div className="targeting-devices__items">
                <button
                    type="button"
                    className={classNames(
                        "targeting-devices__item",
                        { "active": values.includes(DeviceType.TV) }
                    )}
                    onClick={( e ) => onClick(DeviceType.TV)}
                >
                    <span className="targeting-devices__icon">
                        <img src="/assets/img/campaign/devices/ico-smart.png" alt=""/>
                    </span>
                    <span className="targeting-devices__description">
                        <span className="targeting-devices__title">Медийная реклама</span>
                        <span className="targeting-devices__text">
                            Мы покажем видеорекламу, которая поможет сформировать положительный образ вашего бренда.
                            Реклама некликабельная и транслируется на Smart TV.
                        </span>
                    </span>
                </button>

                <button
                    type="button"
                    className={classNames(
                        "targeting-devices__item",
                        { "active": values.includes(DeviceType.PHONE) }
                    )}
                    onClick={( e ) => onClick(DeviceType.PHONE)}
                >
                    <span className="targeting-devices__icon">
                        <img src="/assets/img/campaign/devices/ico-phone.png" alt=""/>
                    </span>
                    <span className="targeting-devices__description">
                        <span className="targeting-devices__title">Performance реклама</span>
                        <span className="targeting-devices__text">
                            Ваша видеореклама сможет подтолкнуть пользователя к необходимому для вас целевому
                            действию - реклама кликабельная.
                        </span>
                    </span>
                </button>
            </div>
        </div>
    )
};

export default TargetingDevices;