import React, { useEffect } from 'react';
import "../styles/Page.sass";
import "./styles/Finances.sass";
import PageTop from "../../../components/PageTop/PageTop";
import FinancesStat from "../../../components/Finances/FinancesStat/FinancesStat";
import FinancesRedistribution from "../../../components/Finances/FinancesRedistribution/FinancesRedistribution";
import FinancesHistory from "../../../components/Finances/FinancesHistory/FinancesHistory";
import { setDocumentTitle } from "../../../helpers/document.helpers";
import { useRecoilValue } from "recoil";
import { getClientsIsExists, getClientsIsLoggedToClient } from "../../../store/Clients.recoil";

const FinancesPage = () => {
    const isLoggedToClient = useRecoilValue(getClientsIsLoggedToClient);
    const isClientsExists = useRecoilValue(getClientsIsExists)

    useEffect(() => {
        setDocumentTitle('Пополнение бюджета');
    }, []);

    return (
        <div className="page finances">
            <PageTop className="finances__top">
                <FinancesStat />
                {!isLoggedToClient && isClientsExists && (<FinancesRedistribution />)}
            </PageTop>

            <FinancesHistory />
        </div>
    );
}

export default FinancesPage;
