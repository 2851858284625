import React, { useEffect, useState } from 'react';
import "./styles/Chips.sass";
import ChipsItem from "./components/ChipsItem";
import { ChipItemValue, IChipItem } from "./components/types/ChipsItem.types";
import { IChipsProps } from "./types/Chips.types";
import { toggleArrayItem } from "../../../helpers/array.helper";

const Chips = ( props: IChipsProps ) => {
    const { items, selectedValues = [], label, isMultiple = false, onSelectedItemsChanged } = props;

    const [values, setValues] = useState<ChipItemValue[]>([]);

    const isChipsItemSelected = ( item: IChipItem ): boolean => {
        return values.includes(item.value);
    }

    const onChipItemClick = ( item: IChipItem ) => {
        const newValues = isMultiple
            ? toggleArrayItem(values, item.value)
            : [item.value];

        setValues([...newValues]);

        if (typeof onSelectedItemsChanged === "function") {
            onSelectedItemsChanged([...newValues]);
        }
    }

    useEffect(() => {
        setValues(selectedValues);
    }, []);

    return (
        <div className="chips">
            {label && (<label className="chips__label">{label}</label>)}

            <div className="chips__list">
                {items.map(( item, index ) => (
                    <ChipsItem
                        className="chips__item"
                        key={index}
                        item={item}
                        isSelected={isChipsItemSelected(item)}
                        onClick={( e ) => onChipItemClick(item)}
                    />
                ))}
            </div>
        </div>
    );
}

export default Chips;