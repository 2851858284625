import { atom, selector } from "recoil";
import { IDictionaryItem, IPaginatedList } from "../models/types";
import { DEFAULT_LIST_ITEMS_COUNT } from "../models/consts";

export interface ICampaignLocalityItem {
    id: number;
    text: string;
    nodes: ICampaignLocalityItem[]
}

export interface ICampaignTargeting {
    localities: ICampaignLocalityItem[];
    intervals: IDictionaryItem[];
    genres: IDictionaryItem[];
    devices: IDictionaryItem[];
    ages: IDictionaryItem[];
    genders: IDictionaryItem[];
}

export interface ICampaignsListItem {
    id: number;
    name: string;
    content: {
        url: string;
    },
    status: string;
    statistics: {
        today: number;
        total: number;
    }
}

export interface ICampaignsList extends IPaginatedList {
    items: ICampaignsListItem[]
}

export interface ICampaignsState {
    targeting: ICampaignTargeting,
    list: ICampaignsList
}

export const campaignsState = atom<ICampaignsState>({
    key: "campaignsState",
    default: {
        targeting: {
            localities: [],
            intervals: [],
            genres: [],
            devices: [],
            ages: [],
            genders: [],
        },
        list: {
            count: 0,
            page: 1,
            limit: DEFAULT_LIST_ITEMS_COUNT,
            items: [],
            isFetching: false,
        }
    }
});

export const getCampaignsTargeting = selector<ICampaignTargeting>({
    key: "campaignsTargeting",
    get: ({ get }) => {
        return get(campaignsState).targeting;
    }
});

export const getCampaignsList = selector<ICampaignsList>({
    key: "campaignsList",
    get: ({ get }) => {
        return get(campaignsState).list;
    }
});

export const getCampaignTargetingLocalities = selector<ICampaignLocalityItem[]>({
    key: "campaignsTargetingLocalities",
    get: ({ get }) => {
        return get(campaignsState).targeting.localities || [];
    }
});

export const getCampaignTargetingFlatLocalities = selector<IDictionaryItem[]>({
    key: "campaignsTargetingFlatLocalities",
    get: ({ get }) => {
        const localities = get(campaignsState).targeting.localities;

        const processNode = (node: ICampaignLocalityItem): any => {
            return node.nodes.reduce((results: any, node) => {
                return [
                    ...results,
                    ...processNode(node),
                ]
            }, [{ value: Number(node.id), label: node.text }])
        }

        return localities.map(processNode).flat();
    }
});

export const getCampaignTargetingIntervals = selector<IDictionaryItem[]>({
    key: "campaignsTargetingIntervals",
    get: ({ get }) => {
        return get(campaignsState).targeting.intervals || [];
    }
});

export const getCampaignTargetingGenres = selector<IDictionaryItem[]>({
    key: "campaignsTargetingGenres",
    get: ({ get }) => {
        return get(campaignsState).targeting.genres || [];
    }
});

export const getCampaignTargetingDevices = selector<IDictionaryItem[]>({
    key: "campaignsTargetingDevices",
    get: ({ get }) => {
        return get(campaignsState).targeting.devices || [];
    }
});

export const getCampaignTargetingAges = selector<IDictionaryItem[]>({
    key: "campaignsTargetingAges",
    get: ({ get }) => {
        return get(campaignsState).targeting.ages || [];
    }
});

export const getCampaignTargetingGenders = selector<IDictionaryItem[]>({
    key: "campaignsTargetingGenders",
    get: ({ get }) => {
        return get(campaignsState).targeting.genders || [];
    }
});