import React from "react";
import "./styles/ClientsList.sass";
import Grid from "../../UI/Grid/Grid";
import { IGridDataCellProps, IGridHeaderCellProps } from "../../UI/Grid/types/Grid.types";
import { IClientsListProps } from "./types/ClientsList.types";
import { clientsDataCellFactory } from "./utils/factories";
import { IApiClientsListItem } from "../../../api/services/clients/clients.response";
import ClientsListCellName from "./components/ClientsListCellName/ClientsListCellName";
import { useSetRecoilState } from "recoil";
import { modalState, ModalType } from "../../../store/Modal.recoil";

const headers: IGridHeaderCellProps[] = [
    {
        title: 'Клиент'
    },
    {
        title: 'Показы'
    },
    {
        title: 'Досмотры'
    },
    {
        title: 'Клики'
    },
    {
        title: 'Потрачено'
    },
    {
        title: ''
    }
];


const ClientsList = (props: IClientsListProps) => {
    const { items, page, limit, count, isFetching } = props;
    const setModal = useSetRecoilState(modalState);

    const data: IGridDataCellProps[] = items.reduce((cells: IGridDataCellProps[], item: IApiClientsListItem) => {
        return [
            ...cells,
            ...clientsDataCellFactory(
                item,
                { name: <ClientsListCellName item={item} /> },
                () => {
                    setModal({
                        type: ModalType.deleteClient,
                        options: { client: item },
                        isOpened: true
                    })
                },
                // () => {
                //     setModal({
                //         type: ModalType.releaseClient,
                //         options: { client: item },
                //         isOpened: true
                //     })
                // },
            )
        ];
    }, []);

    return (
        <div className="clients-list">
            <Grid
                className="clients-list__grid"
                headers={headers}
                data={data}
                page={page}
                limit={limit}
                count={count}
                isFetching={isFetching}
                columnsTemplate="3fr 1fr 1fr 1fr 1fr 64px"
            />
        </div>
    )
}

export default ClientsList;