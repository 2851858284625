import React from 'react';
import './styles/StatisticsListEmpty.sass';
import Button from "../../../../UI/Button/Button";
import { useNavigate } from "react-router-dom";

const StatisticsListEmpty = () => {
    const navigate = useNavigate();

    const onButtonClick = (): void => {
        navigate('/campaigns/create');
    }

    return (
        <div className="statistics-list-empty">
            <p className="statistics-list-empty__text">Здесь будет статистика по рекламным кампаниям</p>
            <Button title="Создать кампанию" className="statistics-list-empty__button" onClick={onButtonClick} />
        </div>
    )
}

export default StatisticsListEmpty;