import React from "react";
import classNames from "classnames";
import "./styles/Button.sass";
import { IButtonProps } from "./types/Button.types";

const Button = (props: IButtonProps) => {
    const { title, className, isSecondary, isWhite, isDisabled, onClick } = props;

    return (
        <button type="button"
                onClick={onClick}
                className={classNames(
                    'button',
                    className,
                    { 'button-secondary': isSecondary },
                    { 'button-white': isWhite }
                )}
                disabled={isDisabled}
        >
            <span className="button__title">{title}</span>
        </button>
    );
};

export default Button;