import React, { ChangeEvent, useEffect, useState } from 'react';
import "./styles/CampaignFormTabCommon.sass";
import TextInput from "../../../../UI/Inputs/TextInput/TextInput";
import DateRangeInput from "../../../../UI/Inputs/DateRangeInput/DateRangeInput";
import Info from "../../../../UI/Info/Info";
import { IDataRangeValue } from "../../../../UI/Inputs/DateRangeInput/types/DateRangeInput.types";
import { ICampaignFormCommonData, ICampaignFormTabCommonProps } from "./types/CampaignFormTabCommon.types";
import ContentFileInput from "./components/ContentFileInput/ContentFileInput";

const CampaignFormTabCommon = (props: ICampaignFormTabCommonProps) => {
    const { values: formValues, errors: formErrors, onTabDataChanged } = props;

    const [formData, setFormData] = useState<ICampaignFormCommonData>({
        name: "",
        targetLink: "",
        startsAt: "",
        endsAt: "",
    });

    const onInputValueChanged = ( e: ChangeEvent<HTMLInputElement> ) => {
        updateFormData({
            [e.target.name]: e.target.value
        })
    }

    const onDataRangeValueChanged = (dateRange: IDataRangeValue)=> {
        const { startsAt, endsAt } = dateRange;

        updateFormData({
            startsAt,
            endsAt
        })
    }

    const onContentFileUploaded = ( file: File, duration?: number ) => {
        updateFormData({
            content: file,
            contentDuration: duration,
            contentSize: file.size
        })
    }

    const onContentFileRemoved = () => {
        updateFormData({
            content: undefined,
            contentDuration: 0,
            contentSize: 0
        })
    }

    const updateFormData = (newData: Object) => {
        const newFormData = {
            ...formData,
            ...newData
        }

        setFormData(newFormData);

        if (typeof onTabDataChanged === "function") {
            onTabDataChanged(newFormData);
        }
    }

    useEffect(() => {
        setFormData({
            name: formValues.name,
            targetLink: formValues.targetLink,
            startsAt: formValues.startsAt,
            endsAt: formValues.endsAt,
        })
    }, [formValues]);

    return (
        <div className="campaign-create-form__fields">
            <TextInput
                isRequired
                label="Название"
                placeholder="Введите название реклам. кампании"
                name="name"
                value={formData.name}
                onChange={onInputValueChanged}
                hasError={!!formErrors?.name}
                errorMessage={formErrors?.name}
            />

            <div className="campaign-create-form__field">
                <DateRangeInput
                    startsAt={formData.startsAt}
                    endsAt={formData.endsAt}
                    label="Период действия рекламной кампании"
                    onValuesChanged={onDataRangeValueChanged}
                />

                <Info className="campaign-create-form__info">
                    Дата окончания Рекламной Кампании не является обязательной. В случае если Вы не
                    установили Дату окончания, Рекламная Кампания будет активна до тех пор, пока вы ее не
                    остановите. Дата старта начинается с 00:00 часов. Дата завершения - до 23:59:59
                </Info>
            </div>

            <div className="campaign-create-form__field">
                <TextInput
                    isRequired
                    label="Ссылка перехода"
                    placeholder="Вставьте ссылку для перехода"
                    name="targetLink"
                    value={formData.targetLink}
                    onChange={onInputValueChanged}
                    hasError={!!formErrors?.targetLink}
                    errorMessage={formErrors?.targetLink}
                />

                <Info className="campaign-create-form__info">
                    Внимание! На указанную ссылку будет перенаправлен пользователь при клике на Вашу рекламу
                </Info>
            </div>

            <div className="campaign-create-form__field">
                <ContentFileInput
                    className="campaign-create-form__content"
                    uploadContent={formData.content}
                    hasError={!!formErrors?.content}
                    errorMessage={formErrors?.content}
                    onFileUploaded={onContentFileUploaded}
                    onFileRemoved={onContentFileRemoved}
                />
            </div>
        </div>
    )
}

export default CampaignFormTabCommon;