import React, { ChangeEvent, useState } from 'react';
import "./styles/TargetingLocalities.sass";
import TextInput from "../../../../../../UI/Inputs/TextInput/TextInput";
import { ITargetingLocalitiesProps } from "./types/TargetingLocalities.types";
import LocalityTargetingItem from "./components/LocalityTargetingItem/LocalityTargetingItem";
import classNames from "classnames";
import { toggleArrayItem } from "../../../../../../../helpers/array.helper";
import { ICampaignLocalityItem } from "../../../../../../../store/Campaigns.recoil";

const TargetingLocalities = ( props: ITargetingLocalitiesProps) => {
    const { items, selectedValues, onSelectedValuesChanged } = props;

    const [searchQuery, setSearchQuery] = useState<string>("");
    const [expandedValues, setExpandedValues] = useState<number[]>([]);

    const onSearchChanged = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.currentTarget.value);
    }

    const onToggleItem = ( item: ICampaignLocalityItem ) => {
        setExpandedValues([
            ...toggleArrayItem(expandedValues, item.id)
        ]);
    }

    const onSelectItem = ( item: ICampaignLocalityItem ) => {
        let newSelectedValues = toggleArrayItem(selectedValues, item.id);
        const isSelected = newSelectedValues.includes(item.id);

        const setChildItemSelected = ( item: ICampaignLocalityItem, isSelected: boolean ) => {
            item.nodes.forEach(( item: ICampaignLocalityItem ) => {
                if (isSelected) {
                    if (!newSelectedValues.includes(item.id)) {
                        newSelectedValues.push(item.id);
                    }
                } else {
                    const i = newSelectedValues.indexOf(item.id);
                    if (i !== -1) {
                        newSelectedValues.splice(i, 1);
                    }
                }
                setChildItemSelected(item, isSelected);
            })
        }

        setChildItemSelected(item, isSelected);

        if (typeof onSelectedValuesChanged === "function") {
            onSelectedValuesChanged([
                ...newSelectedValues,
            ]);
        }
    }

    return (
        <div className={classNames("locality-targeting", { "is-search": searchQuery.length > 0 })}>
            <label className="locality-targeting__label" htmlFor="campaignform-regions">Выберите область, город</label>
            <TextInput
                className="locality-targeting__search"
                placeholder="Найти область, город"
                onChange={onSearchChanged}
            />

            <div className="locality-targeting__items">
                {items.map((item) => (
                    <LocalityTargetingItem
                        key={item.id}
                        item={item}
                        selectedValues={selectedValues}
                        expandedValues={expandedValues}
                        level={1}
                        onToggleItem={onToggleItem}
                        onSelectItem={onSelectItem}
                    />
                ))}
            </div>
        </div>
    );
}

export default TargetingLocalities;