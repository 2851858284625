import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from "recoil";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import API from "./api";

import App from "./App";
import CampaignsPage from "./routes/Pages/CampaignsPage/CampaignsPage";
import ClientsPage from "./routes/Pages/ClientsPage/ClientsPage";
import AccessPage from "./routes/Pages/AccessPage/AccessPage";
import FinancesPage from "./routes/Pages/FinancesPage/FinancesPage";
import StatisticsPage from "./routes/Pages/StatisticsPage/StatisticsPage";
import ErrorPage from "./routes/Pages/ErrorPage/ErrorPage";
import ProfilePage from "./routes/Pages/ProfilePage/ProfilePage";
import CampaignEditPage from "./routes/Pages/CampaignEditPage/CampaignEditPage";
import DocumentsPage from "./routes/Pages/DocumentsPage/DocumentsPage";
import { getAccessToken, storeAccessToken } from "./helpers/accessToken.helpers";
import { redirectToHome } from "./helpers/redirect.helper";
import CampaignViewPage from "./routes/Pages/CampaignViewPage/CampaignViewPage";

storeAccessToken();
const accessToken = getAccessToken();

if (!accessToken) { redirectToHome(); }
if (accessToken) { API.setToken(accessToken); }

if (window.location.pathname === '/') {
    window.location.replace('/clients');
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "/profile",
                element: <ProfilePage />,
            },
            {
                path: "/campaigns",
                element: <CampaignsPage />,
            },
            {
                path: "/campaigns/create",
                element: <CampaignEditPage />,
            },
            {
                path: "/campaigns/:id/edit",
                element: <CampaignEditPage />,
            },
            {
                path: "/campaigns/:id/view",
                element: <CampaignViewPage />,
            },
            {
                path: "/clients",
                element: <ClientsPage />,
            },
            // {
            //     path: "/access",
            //     element: <AccessPage />,
            // },
            {
                path: "/finances",
                element: <FinancesPage />,
            },
            {
                path: "/statistics",
                element: <StatisticsPage />,
            },
            {
                path: "/documents",
                element: <DocumentsPage />,
            },
        ],
        errorElement: <ErrorPage />,
    }
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLDivElement
);

root.render(
    <RecoilRoot>
        <RouterProvider router={router} />
    </RecoilRoot>
);