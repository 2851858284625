import React from "react";
import { ISvgIconProps } from "./types/SvgIcon.types";

const RadioCheckedIcon = (props: ISvgIconProps) => {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10.5" r="7.5" stroke="#0D81EC"/>
            <circle cx="10" cy="10.5" r="5" fill="#0D81EC"/>
        </svg>
    );
};

export default RadioCheckedIcon;