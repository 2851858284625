import React, { ChangeEvent, useEffect, useState } from 'react';
import "./styles/TargetingLocalities.sass";
import TextInput from "../../../../../../UI/Inputs/TextInput/TextInput";
import { ITargetingLocalitiesProps } from "./types/TargetingLocalities.types";
import LocalityTargetingItem from "./components/LocalityTargetingItem/LocalityTargetingItem";
import classNames from "classnames";
import { toggleArrayItem } from "../../../../../../../helpers/array.helper";

const TargetingLocalities = ( props: ITargetingLocalitiesProps) => {
    const { items, onSelectedItemsChanged } = props;

    const [searchQuery, setSearchQuery] = useState<string>("");
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [expandedItems, setExpandedItems] = useState<number[]>([]);

    const onSearchChanged = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.currentTarget.value);
    }

    const onToggleItem = ( itemId: number ) => {
        const newExpandedItems = toggleArrayItem(expandedItems, itemId);
        setExpandedItems([...newExpandedItems]);
    }

    const onSelectItem = ( itemId: number ) => {
        items.forEach(( item ) => {

        })

        const newSelectedItems = toggleArrayItem(selectedItems, itemId);
        setSelectedItems([...newSelectedItems]);
    }

    useEffect(() => {
        if (typeof onSelectedItemsChanged === "function") {
            onSelectedItemsChanged(selectedItems);
        }
    }, [selectedItems]);

    return (
        <div className={classNames("locality-targeting", { "is-search": searchQuery.length > 0 })}>
            <label className="locality-targeting__label" htmlFor="campaignform-regions">Выберите область, город</label>
            <TextInput
                className="locality-targeting__search"
                placeholder="Найти область, город"
                onChange={onSearchChanged}
            />

            <div className="locality-targeting__items">
                {items.map((item) => (
                    <LocalityTargetingItem
                        key={item.id}
                        item={item}
                        selectedItems={selectedItems}
                        expandedItems={expandedItems}
                        level={1}
                        onToggleItem={onToggleItem}
                        onSelectItem={onSelectItem}
                    />
                ))}
            </div>
        </div>
    );
}

export default TargetingLocalities;