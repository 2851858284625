import React, { useEffect, useState } from 'react';
import './styles/CampaignsList.sass';
import { ICampaignsListProps } from "./types/CampaignsList.types";
import { ICampaignsListItemProps} from "../CampaignsListItem/types/CampaignsListItem.types";
import CampaignsListItem from "../CampaignsListItem/CampaignsListItem";
import API from "../../../api";
import { useRecoilValue } from "recoil";
import { getClientsCurrentClient } from "../../../store/Clients.recoil";
import classNames from "classnames";
import CampaignsListEmpty from "./components/CampaignsListEmpty/CampaignsListEmpty";

const CampaignsList = (props: ICampaignsListProps) => {
    const currentClient = useRecoilValue(getClientsCurrentClient);

    const [items, setItems] = useState<ICampaignsListItemProps[]>([]);

    useEffect(() => {
        API.campaigns.list(currentClient?.id)
            .then(({ results }) => {
                setItems(results);
            })
            .catch((error) => {
                console.log(error);
            })
    }, [currentClient]);

    return (
        <div className={classNames("campaigns-list", { "is-empty": items.length === 0 })}>
            {items.length === 0 && (<CampaignsListEmpty />)}
            {items.map((item: ICampaignsListItemProps, i: number) => (
                <CampaignsListItem key={i} item={item} />
            ))}
        </div>
    )
}

export default CampaignsList;