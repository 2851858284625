import React from 'react';
import './styles/DocumentsListEmpty.sass';
import Button from "../../../../UI/Button/Button";
import { useNavigate } from "react-router-dom";

const DocumentsListEmpty = () => {
    const navigate = useNavigate();

    const onButtonClick = (): void => {
        navigate('/campaigns/create');
    }

    return (
        <div className="documents-list-empty">
            <p className="documents-list-empty__text">Здесь будут документы по рекламным кампаниям</p>
            <Button title="Создать кампанию" className="documents-list-empty__button" onClick={onButtonClick} />
        </div>
    )
}

export default DocumentsListEmpty;