import React from "react";
import "./styles/CampaignViewStatistics.sass";
import classNames from "classnames";
import { ICampaignViewStatisticsProps } from "./types/CampaignViewStatistics.types";
import Grid from "../../../../UI/Grid/Grid";
import { IGridHeaderCellProps } from "../../../../UI/Grid/types/Grid.types";

const headers: IGridHeaderCellProps[] = [
    {
        column: 'title',
        title: 'Название'
    },
    {
        column: 'status',
        title: 'Дата'
    },
    {
        column: 'amount',
        title: 'Переходы'
    },
    {
        column: 'date',
        title: 'Показы'
    },
    {
        column: 'date',
        title: 'Полный просмотр'
    },
    {
        column: 'date',
        title: 'Сумма списаний'
    },
];

const CampaignViewStatistics = ( props: ICampaignViewStatisticsProps ) => {
    const { className } = props;

    const isFetching = false;

    const grid = {
        items: [],
        page: 1,
        limit: 25,
        count: 0
    }

    return (
        <div className={classNames("campaign-view-statistics", className)}>
            <div className="campaign-view-statistics__title">Статистика</div>
            <Grid
                className="campaign-view-statistics"
                columnsTemplate="repeat(6, 1fr)"
                headers={headers}
                data={grid.items}
                page={grid.page}
                limit={grid.limit}
                count={grid.count}
                isFetching={isFetching}
            />
        </div>
    )
}

export default CampaignViewStatistics;