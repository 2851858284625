import React from "react";
import { ISvgIconProps } from "./types/SvgIcon.types";

const CrossIcon = (props: ISvgIconProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M6.56066 4.43934C5.97487 3.85355 5.02513 3.85355 4.43934 4.43934C3.85355 5.02513 3.85355 5.97487 4.43934 6.56066L9.87914 12.0005L4.43977 17.4398C3.85398 18.0256 3.85398 18.9754 4.43977 19.5611C5.02556 20.1469 5.9753 20.1469 6.56109 19.5611L12.0005 14.1218L17.4398 19.5611C18.0256 20.1469 18.9754 20.1469 19.5611 19.5611C20.1469 18.9754 20.1469 18.0256 19.5611 17.4398L14.1218 12.0005L19.5616 6.56066C20.1474 5.97487 20.1474 5.02513 19.5616 4.43934C18.9758 3.85355 18.026 3.85355 17.4403 4.43934L12.0005 9.87914L6.56066 4.43934Z"
                  fill="black"/>
        </svg>
    );
};

export default CrossIcon;