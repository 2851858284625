import React, { useEffect, useState } from 'react';
import "./styles/TargetingDropdown.sass";
import {
    ITargetingDropdownProps,
    ITargetingDropdownItem,
    TargetingDropdownItemValue
} from "./types/TargetingDropdown.types";
import TargetingDropdownTag from "./components/TargetingDropdownTag/TargetingDropdownTag";
import TargetingDropdownItem from "./components/TargetingDropdownItem/TargetingDropdownItem";
import ArrowDownIcon from "../../../../../../UI/SvgIcons/ArrowDownIcon";
import classNames from "classnames";
import { toggleArrayItem } from "../../../../../../../helpers/array.helper";

const TargetingDropdown = ( props: ITargetingDropdownProps ) => {
    const { label, placeholder, items, selectedValues = [], onSelectedItemsChanged } = props;

    const [values, setValues] = useState<TargetingDropdownItemValue[]>([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isDropdownListScrolled, setIsDropdownListScrolled] = useState(false);

    const onDropdownToggleClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setIsDropdownListScrolled(false);
    }

    const onDropdownListScroll = ( e: any ) => {
        const { offsetHeight, scrollTop, scrollHeight } = e.target;
        setIsDropdownListScrolled(offsetHeight + scrollTop >= scrollHeight - 6);
    }

    const toggleDropdownItem = ( item: ITargetingDropdownItem ) => {
        const newValues = toggleArrayItem(values, item.value);
        setValues([ ...newValues ]);
    }

    const onTargetingDropdownItemClick = ( item: ITargetingDropdownItem ) => {
        toggleDropdownItem(item);
        setSelectedItems();
    }

    const onSelectAllButtonClick = () => {
        if (items.length !== values.length) {
            items.filter((item) => !values.includes(item.value))
                .forEach((item) => toggleDropdownItem(item));
        } else {
            setValues([]);
        }

        setSelectedItems();
    }

    const onDropdownTagClick = ( item: ITargetingDropdownItem ) => {
        toggleDropdownItem(item);
        setSelectedItems();
    }

    const onDoneButtonClick = () => {
        setIsDropdownOpen(false);
    }

    const setSelectedItems = (): void => {
        if (typeof onSelectedItemsChanged === "function") {
            onSelectedItemsChanged(values);
        }
    }

    const isDropdownItemSelected = ( item: ITargetingDropdownItem ): boolean => {
        return values.includes(item.value);
    }

    const isAllDropdownItemSelected = (): boolean => {
        return values.length === items.length;
    }

    useEffect(() => {
        console.log(selectedValues);
        console.log(values);
    }, []);

    return (
        <div className={classNames("targeting-dropdown", { "is-opened": isDropdownOpen })}>
            {label && (<div className="targeting-dropdown__label">{label}</div>)}
            <div className="targeting-dropdown__input">
                {placeholder && values.length === 0 && (
                    <div className="targeting-dropdown__placeholder" onClick={onDropdownToggleClick}>
                        {placeholder}
                    </div>
                )}

                {values.length > 0 && (
                    <div className="targeting-dropdown__tags">
                        { items
                            .filter(( item ) => values.includes(item.value))
                            .map(( item, index ) => (
                                <TargetingDropdownTag
                                    className="targeting-dropdown__tag"
                                    key={index}
                                    item={item}
                                    onTagClick={onDropdownTagClick}
                                />
                            ))
                        }
                    </div>
                )}

                <button type="button" className="targeting-dropdown__toggle" onClick={onDropdownToggleClick}>
                    <ArrowDownIcon/>
                </button>
            </div>

            {isDropdownOpen && (
                <div className={classNames("targeting-dropdown__list", { "is-list-scrolled": isDropdownListScrolled })}
                     onScroll={onDropdownListScroll}
                >
                    <div className="targeting-dropdown__items">
                        {items.map(( item, index ) => {
                            return (
                                <TargetingDropdownItem
                                    className="targeting-dropdown__item"
                                    key={index}
                                    item={item}
                                    isSelected={isDropdownItemSelected(item)}
                                    onItemClick={onTargetingDropdownItemClick}
                                />
                            )
                        })}
                    </div>
                    <div className="targeting-dropdown__item targeting-dropdown__item-last">
                        <TargetingDropdownItem
                            item={{ label: "Выбрать всё", value: "" }}
                            isSelected={isAllDropdownItemSelected()}
                            onItemClick={onSelectAllButtonClick}
                        />
                        <button type="button" onClick={onDoneButtonClick}>Готово</button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TargetingDropdown;