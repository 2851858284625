import React from "react";
import { ISvgIconProps } from "./types/SvgIcon.types";

const InfoIcon = (props: ISvgIconProps) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 2.8125C5.58274 2.8125 2.8125 5.58274 2.8125 9C2.8125 12.4173 5.58274 15.1875 9 15.1875C12.4173 15.1875 15.1875 12.4173 15.1875 9C15.1875 5.58274 12.4173 2.8125 9 2.8125ZM1.6875 9C1.6875 4.96142 4.96142 1.6875 9 1.6875C13.0386 1.6875 16.3125 4.96142 16.3125 9C16.3125 13.0386 13.0386 16.3125 9 16.3125C4.96142 16.3125 1.6875 13.0386 1.6875 9ZM8.40015 6C8.40015 5.68934 8.65199 5.4375 8.96265 5.4375H9.03735C9.34801 5.4375 9.59985 5.68934 9.59985 6V6.075C9.59985 6.22421 9.54057 6.3673 9.43505 6.4728C9.32953 6.57829 9.18642 6.63754 9.03722 6.6375L8.96251 6.63748C8.6519 6.63741 8.40015 6.38559 8.40015 6.07498V6ZM9 7.6875C9.31066 7.6875 9.5625 7.93934 9.5625 8.25V12C9.5625 12.3107 9.31066 12.5625 9 12.5625C8.68934 12.5625 8.4375 12.3107 8.4375 12V8.25C8.4375 7.93934 8.68934 7.6875 9 7.6875Z" fill="#697D91"/>
        </svg>
    );
};

export default InfoIcon;