import React from 'react';
import "./styles/PageTop.sass";
import { IPageTopProps } from "./types/PageTop.types";
import classNames from "classnames";
import { useRecoilValue } from "recoil";
import { getClientsCurrentClient, getClientsIsLoggedToClient } from "../../store/Clients.recoil";

const PageTop = (props: IPageTopProps) => {
    const { children, className } = props;

    const isLoggedToClient = useRecoilValue(getClientsIsLoggedToClient);
    const currentClient = useRecoilValue(getClientsCurrentClient);

    return (
        <div className={classNames('page-top', className)}>
            {isLoggedToClient && currentClient && (<div className="page-top__client">Клиент: {currentClient.name}</div>)}
            <div className="page-top__wrapper">
                { children }
            </div>
        </div>
    );
}

export default PageTop;
