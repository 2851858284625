import { GridCellType, IGridDataCellProps } from "../../../UI/Grid/types/Grid.types";
import { IClientsListItem } from "../../../../store/Clients.recoil";

export const clientsDataCellFactory = (
    item: IClientsListItem,
    hiddenFields: string[] = [],
    cellRenderers: any = undefined,
    deleteHandler: any = undefined,
    // releaseHandler: any = undefined,
): IGridDataCellProps[] => {
    const dataCells = Object.keys(item)
        .filter(( key: string ) => !hiddenFields.includes(key))
        .map(( key: string ) => {
            return {
                column: key,
                value: typeof cellRenderers[key] !== 'undefined' ? cellRenderers[key] : (item as any)[key]
            }
        });

    const actionsCell = {
        column: GridCellType.ACTIONS,
        value: [
            {
                title: 'Удалить клиента',
                handler: () => {
                    deleteHandler();
                },
            },
            // {
            //     title: 'Дать доступ клиенту',
            //     handler: () => {
            //         releaseHandler();
            //     }
            // },
        ]
    }

    return [
        ...dataCells,
        actionsCell
    ]
}