import React from 'react';
import "./styles/ClientsPlates.sass";
import classNames from "classnames";
import { IClientsPlatesProps } from "./types/ClientsPlates.types";
import ClientPlate from "./components/ClientPlate/ClientPlate";

const ClientsPlates = ( props: IClientsPlatesProps ) => {
    const { plates, className, page = 1, limit = 25, count = 25 } = props;

    const getStartPosition = (): number => {
        return (page - 1) * limit + 1;
    }

    const getEndPosition = (): number => {
        return count < limit ? count : getStartPosition() + limit - 1;
    }

    return (
        <div className={classNames('clients-plates', className)}>
            <div className="client-plates__top">
                Показаны записи <strong>{getStartPosition()}-{getEndPosition()} из { count }</strong>
            </div>

            <div className="client-plates__items">
                { plates.map(( plate ) => (
                    <ClientPlate className="clients-list__item" item={plate} key={plate.id} />
                )) }
            </div>
        </div>
    );
}

export default ClientsPlates;
