import { HTMLAttributes } from "react";

export interface IGridHeaderCellProps {
    column?: string;
    title: string;
}

export enum GridCellType {
    DATA= 'data',
    ACTIONS = 'actions'
}

export interface IGridDataCellProps {
    column?: GridCellType | string;
    value: any;
}

export interface IGridRowAction {
    title: string;
    handler: (data?: any) => void;
}

export interface IGridCollection {
    count: number;
    page: number;
    limit: number;
    items: any[];
}

export interface IGridProps extends HTMLAttributes<HTMLDivElement> {
    headers: IGridHeaderCellProps[];
    data: IGridDataCellProps[];
    count: number;
    page: number;
    limit: number;
    rowsTemplate?: string;
    columnsTemplate?: string;
    isFetching: boolean;
}