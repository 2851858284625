import React from 'react';
import "./styles/SupportModal.sass";
import Modal from 'components/UI/Modal/Modal';
import CopyIcon from "../UI/SvgIcons/CopyIcon";
import { useSetRecoilState } from "recoil";
import { toastState } from "../../store/Toast.recoil";
import { ToastType } from "../UI/Toast/types/Toast.types";
import Button from "../UI/Button/Button";
import { modalState } from "../../store/Modal.recoil";

const SUPPORT_EMAIL = 'info@vtochku.online';

const SupportModal = () => {
    const setToastState = useSetRecoilState(toastState);
    const setModalState = useSetRecoilState(modalState);

    const onCopyEmailButtonClick = () => {
        if (window.isSecureContext) {
            try {
                window.navigator.clipboard.writeText(SUPPORT_EMAIL).then(() => {});
                setToastState({
                    type: ToastType.SUCCESS,
                    message: "Email скопирован",
                    isShown: true
                })
            } catch (e) {
                console.error(e)
            }
        } else {
            setToastState({
                type: ToastType.ERROR,
                message: "Email не может быть скопирован",
                isShown: true
            })
        }
    }

    const onChatButtonClick = () => {
        // @ts-ignore
        const { BXLiveChat } = window;
        if (typeof BXLiveChat !== "undefined") {
            setModalState({
                type: null,
                isOpened: false
            });
            BXLiveChat.open();
        }
    }

    return (
        <Modal justifyContent="center" className="support-modal">
            <div className="support-modal__content">
                <div className="support-modal__header">
                    <div className="support-modal__title">Контакты</div>
                </div>

                <div className="support-modal__body">
                    <div className="support-modal__row">
                        <span>Почта:</span> <a href={`mailto:${SUPPORT_EMAIL}`} target="_blank">{SUPPORT_EMAIL}</a>
                        <button className="support-modal__copy" type="button" onClick={onCopyEmailButtonClick}>
                            <CopyIcon />
                        </button>
                    </div>
                    <div className="support-modal__row">
                        <span>Режим работы:</span> <strong>ПН-ПТ с 07:00 до 16:00</strong>
                    </div>
                    <div className="support-modal__row">
                        <Button
                            className="support-modal__chat"
                            onClick={onChatButtonClick}
                            title="Чат"
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default SupportModal;
