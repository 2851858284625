import axios from 'axios';
import { IApiClientsListResponse } from "./clients.response";
import { IApiClientsCreateRequest } from "./clients.requests";

class ClientsApi {
    list = async (): Promise<IApiClientsListResponse> => {
        const response = await axios.get('/clients/list');

        return response.data;
    }

    suggestions = async (q: string): Promise<IApiClientsListResponse> => {
        const response = await axios.get(`/clients/suggestions?q=${q}`);

        return response.data;
    }

    create = (data: IApiClientsCreateRequest) => {
        return axios.post('/clients/create', data);
    }

    delete = async (id: number) => {
        return await axios.post('/clients/delete', { id });
    }

    release = (id: number) => {
        return axios.post('/clients/release', { id });
    }

    transfer = (id: number) => {
        return axios.post('/clients/transfer', { id });
    }
}

const clientAPI = new ClientsApi();
export default clientAPI;