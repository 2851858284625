import React, { useState } from 'react';
import "./styles/FinancesStat.sass";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState, ModalType } from "../../../store/Modal.recoil";

import Button from "../../UI/Button/Button";
import { getProfilePersonBalance } from "../../../store/Profile.recoil";
import { prettifyMoneyValue } from "../../../helpers/prettifier.helpers";
import InfoIcon from "../../UI/SvgIcons/InfoIcon";

const FinancesStat = () => {
    const setModal = useSetRecoilState(modalState);
    const profileBalance = useRecoilValue(getProfilePersonBalance);
    const [hintVisible, setHintVisibale] = useState<null|number>(null);

    const openFinancesAddAmountModal = () => {
        setModal({
            type: ModalType.financesAddAmount,
            isOpened: true
        })
    }

    const onInfoIconClick = (id: number) => {
        if (id === hintVisible) {
            setHintVisibale(null);
            return;
        }

        setHintVisibale(id);
        setTimeout(() => {
            setHintVisibale(null);
        }, 3000)
    }

    return (
        <div className="finances-stat">
            <div className="finances-stat__column">
                <div className="finances-stat__title">Всего</div>
                <div className="finances-stat__value"
                     dangerouslySetInnerHTML={{__html: prettifyMoneyValue(profileBalance) }}
                ></div>

                <Button
                    title="Пополнить баланс"
                    className="finances-stat__button"
                    onClick={openFinancesAddAmountModal}
                />
            </div>

            <div className="finances-stat__column">
                <div className="finances-stat__used">
                    <div className="finances-stat__title">
                        <div>Используется</div>
                        <button onClick={() => onInfoIconClick(0)}>
                            <InfoIcon />
                        </button>
                        {hintVisible === 0 && (
                            <div className="finances-stat__hint">Общая сумма, на которую запущены рекламные кампании</div>
                        )}
                    </div>
                    <div
                        className="finances-stat__value finances-stat__value-gray"
                        dangerouslySetInnerHTML={{__html: prettifyMoneyValue(0) }}
                    ></div>
                </div>

                <div className="finances-stat__used">
                    <div className="finances-stat__title">
                        <div>На счетах клиентов</div>
                        <button onClick={() => onInfoIconClick(1)}>
                            <InfoIcon />
                        </button>
                        {hintVisible === 1 && (
                            <div className="finances-stat__hint">Общая сумма, которая имеется на балансе всех клиентов</div>
                        )}
                    </div>
                    <div
                        className="finances-stat__value finances-stat__value-gray"
                        dangerouslySetInnerHTML={{__html: prettifyMoneyValue(0) }}
                    ></div>
                </div>
            </div>
        </div>
    );
}

export default FinancesStat;
