import React from "react";
import { ISvgIconProps } from "./types/SvgIcon.types";

const ArrowDownIcon = (props: ISvgIconProps) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 7L10 13L4 7" stroke="#8D99A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default ArrowDownIcon;