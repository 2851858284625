import React from 'react';
import Button from "../../UI/Button/Button";

import "./styles/FinancesRedistribution.sass";
import { useSetRecoilState } from "recoil";
import { modalState, ModalType } from "../../../store/Modal.recoil";

const FinancesRedistribution = () => {
    const setModalState = useSetRecoilState(modalState);

    const onRedistributionButtonClick = () => {
        setModalState({
            type: ModalType.financesRedistribution,
            isOpened: true
        })
    }

    return (
        <div className="finances-redistribution">
            <div className="finances-redistribution__text">Распределение бюджета между клиентами</div>
            <div className="finances-redistribution__button">
                <Button
                    title="Перевести"
                    isSecondary
                    onClick={onRedistributionButtonClick}
                />
            </div>
        </div>
    );
}

export default FinancesRedistribution;
