import React, { ChangeEvent } from 'react';
import "./styles/CampaignFormTabCommon.sass";
import TextInput from "../../../../UI/Inputs/TextInput/TextInput";
import DateRangeInput from "../../../../UI/Inputs/DateRangeInput/DateRangeInput";
import Info from "../../../../UI/Info/Info";
import { IDataRangeValue } from "../../../../UI/Inputs/DateRangeInput/types/DateRangeInput.types";
import { ICampaignFormTabCommonProps } from "./types/CampaignFormTabCommon.types";
import ContentFileInput from "./components/ContentFileInput/ContentFileInput";

const CampaignFormTabCommon = (props: ICampaignFormTabCommonProps) => {
    const { values: formValues, errors: formErrors, onTabDataChanged } = props;

    const onInputValueChanged = ( e: ChangeEvent<HTMLInputElement> ) => {
        updateFormData({
            [e.target.name]: e.target.value
        })
    }

    const onDataRangeValueChanged = (dateRange: IDataRangeValue)=> {
        const { startsAt, endsAt } = dateRange;

        updateFormData({
            startsAt,
            endsAt
        })
    }

    const onContentFileUploaded = ( file: File, duration?: number ) => {
        updateFormData({
            content: {
                file: file,
                duration: duration,
                size: file.size,
                name: file.name,
            }
        })
    }

    const onContentFileRemoved = () => {
        updateFormData({
            content: {
                file: undefined,
                duration: 0,
                size: 0,
                name: ''
            }
        })
    }

    const updateFormData = (newData: Object) => {
        if (typeof onTabDataChanged === "function") {
            onTabDataChanged({
                ...formValues,
                ...newData
            });
        }
    }

    return (
        <div className="campaign-create-form__fields">
            <TextInput
                isRequired
                label="Название"
                placeholder="Введите название реклам. кампании"
                name="name"
                value={formValues.name}
                onChange={onInputValueChanged}
                hasError={!!formErrors?.name}
                errorMessage={formErrors?.name}
            />

            <div className="campaign-create-form__field">
                <DateRangeInput
                    startsAt={formValues.startsAt}
                    endsAt={formValues.endsAt}
                    label="Период действия рекламной кампании"
                    onValuesChanged={onDataRangeValueChanged}
                />

                <Info className="campaign-create-form__info">
                    Дата окончания Рекламной Кампании не является обязательной. В случае если Вы не
                    установили Дату окончания, Рекламная Кампания будет активна до тех пор, пока вы ее не
                    остановите. Дата старта начинается с 00:00 часов. Дата завершения - до 23:59:59
                </Info>
            </div>

            <div className="campaign-create-form__field">
                <TextInput
                    isRequired
                    label="Ссылка перехода"
                    placeholder="Вставьте ссылку для перехода"
                    name="targetLink"
                    value={formValues.targetLink}
                    onChange={onInputValueChanged}
                    hasError={!!formErrors?.targetLink}
                    errorMessage={formErrors?.targetLink}
                />

                <Info className="campaign-create-form__info">
                    Внимание! На указанную ссылку будет перенаправлен пользователь при клике на Вашу рекламу
                </Info>
            </div>

            <div className="campaign-create-form__field">
                <ContentFileInput
                    className="campaign-create-form__content"
                    uploadContent={formValues.content}
                    hasError={!!formErrors?.content}
                    errorMessage={formErrors?.content}
                    onFileUploaded={onContentFileUploaded}
                    onFileRemoved={onContentFileRemoved}
                />
            </div>
        </div>
    )
}

export default CampaignFormTabCommon;