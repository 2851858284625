import axios from 'axios';
import { IApiClientsInfoRequest, ICompanySaveRequest } from "./companies.request";

class CompaniesApi {
    info = async (params?: IApiClientsInfoRequest) => {
        try {
            const { data } = await axios.get(`/companies`, { params });

            return data;
        } catch (error) {
            throw error;
        }
    }

    create = (values: ICompanySaveRequest) => {

    }
}

const companiesAPI = new CompaniesApi();
export default companiesAPI;