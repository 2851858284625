export const setDocumentTitle = (title: string = ""): void => {
    if (title !== "") {
        title = title + ' | '
    }

    document.title = title + getDocumentDefaultTitle();
}

export const getDocumentDefaultTitle = (): string => {
    return 'Партнерский аккаунт'
}