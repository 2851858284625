import { atom, selector } from "recoil";

export enum ModalType {
    profile = 'profileModal',
    financesAdd = 'financesAddModal',
    financesAddAmount = 'financesAddAmountModal',
    financesRedistribution = 'financesRedistributionModal',
    createClient = 'createClientModal',
    deleteClient = 'deleteClientModal',
    releaseClient = 'releaseClientModal',
    transferClient = 'transferClientModal',
    campaignAdvertiseMark = 'campaignAdvertiseMarkModal',
    support = 'supportModal'
}

export interface IModalState {
    type: null | ModalType,
    options?: any,
    isOpened: boolean;
}

export const modalState = atom<IModalState>({
    key: "modalState",
    default: {
        type: null,
        options: {},
        isOpened: false
    }
})

export const getModalOptions = selector<any>({
    key: "modalOptions",
    get: ({ get }) => {
        const modal: IModalState = get(modalState);

        return modal.options;
    }
});

export const getIsAnyModalOpened = selector<boolean>({
    key: "isAnyModalOpened",
    get: ({ get }) => {
        const modal: IModalState = get(modalState);

        return modal.isOpened;
    }
});

export const getIsProfileModalOpened = selector<boolean>({
    key: "isProfileModalOpened",
    get: ({ get }) => {
        const modal: IModalState = get(modalState);

        return modal.isOpened && modal.type === ModalType.profile;
    }
});

export const getIsFinancesAddModalOpened = selector<boolean>({
    key: "isFinancesAddModalOpened",
    get: ({ get }) => {
        const modal: IModalState = get(modalState);

        return modal.isOpened && modal.type === ModalType.financesAdd;
    }
});

export const getIsFinancesAddAmountModalOpened = selector<boolean>({
    key: "isFinancesAddAmountModalOpened",
    get: ({ get }) => {
        const modal: IModalState = get(modalState);

        return modal.isOpened && modal.type === ModalType.financesAddAmount;
    }
});

export const getIsFinancesRedistributionModalOpened = selector<boolean>({
    key: "isFinancesFinancesRedistributionModalOpened",
    get: ({ get }) => {
        const modal: IModalState = get(modalState);

        return modal.isOpened && modal.type === ModalType.financesRedistribution;
    }
});

export const getIsCreateClientModalOpened = selector<boolean>({
    key: "isCreateClientModalOpened",
    get: ({ get }) => {
        const modal: IModalState = get(modalState);

        return modal.isOpened && modal.type === ModalType.createClient;
    }
});

export const getIsDeleteClientModalOpened = selector<boolean>({
    key: "isDeleteClientModalOpened",
    get: ({ get }) => {
        const modal: IModalState = get(modalState);

        return modal.isOpened && modal.type === ModalType.deleteClient;
    }
});

export const getIsReleaseClientModalOpened = selector<boolean>({
    key: "isReleaseClientModalOpened",
    get: ({ get }) => {
        const modal: IModalState = get(modalState);

        return modal.isOpened && modal.type === ModalType.releaseClient;
    }
});

export const getIsTransferClientModalOpened = selector<boolean>({
    key: "isTransferClientModalOpened",
    get: ({ get }) => {
        const modal: IModalState = get(modalState);

        return modal.isOpened && modal.type === ModalType.transferClient;
    }
});

export const getIsCampaignAdvertiseMarkModalOpened = selector<boolean>({
    key: "isCampaignAdvertiseMarkModalOpened",
    get: ({ get }) => {
        const modal: IModalState = get(modalState);

        return modal.isOpened && modal.type === ModalType.campaignAdvertiseMark;
    }
});

export const getIsSupportModalOpened = selector<boolean>({
    key: "isSupportModalOpened",
    get: ({ get }) => {
        const modal: IModalState = get(modalState);

        return modal.isOpened && modal.type === ModalType.support;
    }
});