import React, { useEffect, useState } from "react";
import "./styles/CampaignViewDetails.sass";
import { ICampaignViewDetailsProps } from "./types/CampaignViewDetails.types";
import classNames from "classnames";
import { IApiCampaignLocality, ICampaign } from "../../../../../api/services/campaigns/campaigns.response";
import { prettifyMoneyValue } from "../../../../../helpers/prettifier.helpers";
import {
    ITargetingDropdownItem
} from "../../../CampaignForm/components/CampaignFormTabTargeting/components/TargetingDropdown/types/TargetingDropdown.types";
import { IChipItem } from "../../../../UI/Chips/components/types/ChipsItem.types";
import API from "../../../../../api";

const CampaignViewDetails = ( props: ICampaignViewDetailsProps ) => {
    const { campaign, className } = props;

    const [localities, setLocalities] = useState<IApiCampaignLocality[]>([]);
    const [intervals, setIntervals] = useState<ITargetingDropdownItem[]>([]);
    const [genres, setGenres] = useState<IChipItem[]>([]);
    const [ages, setAges] = useState<IChipItem[]>([]);
    const [genders, setGenders] = useState<IChipItem[]>([]);

    // TODO: вынести справочники в общий стейт для всего аппа
    useEffect(() => {
        API.campaigns.localities()
            .then(( { data } ) => {
                setLocalities(data)
            });

        API.campaigns.intervals()
            .then(( { data } ) => {
                const intervals: IChipItem[] = Object.entries(data)
                    .map(( [key, value] ) => {
                        return {
                            label: String(value),
                            value: key
                        };
                    });
                setIntervals(intervals);
            });

        API.campaigns.genres()
            .then(( { data } ) => {
                const genres: IChipItem[] = Object.entries(data)
                    .map(( [key, value] ) => {
                        return {
                            label: String(value),
                            value: key
                        };
                    });

                setGenres(genres);
            });

        API.campaigns.ages()
            .then(( { data } ) => {
                const ages: IChipItem[] = Object.entries(data)
                    .map(( [key, value] ) => {
                        return {
                            label: String(value),
                            value: key
                        };
                    });

                setAges(ages);
            });

        API.campaigns.genders()
            .then(( { data } ) => {
                setGenders(data.map(
                    ( value, index ) => {
                        return {
                            label: String(value),
                            value: index
                        }
                    }
                ));
            })
    }, []);

    const getPeriod = ( campaign: ICampaign ): string => {
        let period = [];
        period.push(campaign.startsAt);

        if (campaign.endsAt) {
            period.push(campaign.endsAt);
        }

        return period.join(' - ');
    }

    return (
        <div className={classNames("campaign-view-details", className)}>
            <div className="campaign-view-details__brief">
                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Статус</div>
                    <div className="campaign-view-details__value">{campaign.statusLabel}</div>
                </div>

                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Период действия</div>
                    <div className="campaign-view-details__value">{getPeriod(campaign)}</div>
                </div>

                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Ссылка</div>
                    <div className="campaign-view-details__value">{campaign.targetLink}</div>
                </div>

                {false && (
                    <div className="campaign-view-details__row">
                        <div className="campaign-view-details__name">Субъекты</div>
                        <div className="campaign-view-details__value"></div>
                    </div>
                )}

                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Время показа</div>
                    <div className="campaign-view-details__value">
                        {
                            intervals
                                .filter((item) => campaign.intervals.includes(Number(item.value)))
                                .map((item, index) => (
                                    <span key={index}>{item.label}, </span>
                                ))
                        }
                    </div>
                </div>

                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Жанры телеканалов</div>
                    <div className="campaign-view-details__value">
                        { genres
                            .filter(( item ) => campaign.genres.includes(String(item.value)))
                            .map(( item, index ) => (
                                <span key={index}>{item.label}</span>
                            ))
                        }
                    </div>
                </div>

                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Тип рекламы</div>
                    <div className="campaign-view-details__value">
                        { campaign.devices.map((device, index) => (
                            <span key={index}>{device === 'tv' ? 'Медийная реклама' : 'Performance реклама'},</span>
                        ))
                        }
                    </div>
                </div>

                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Возраст</div>
                    <div className="campaign-view-details__value">
                        { ages
                            .filter(( item ) => campaign.age.includes(Number(item.value)))
                            .map(( item, index ) => (
                                <span key={index}>{item.label}</span>
                            ))
                        }
                    </div>
                </div>

                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Пол</div>
                    <div className="campaign-view-details__value">
                        { genders
                            .filter(( item ) => campaign.sex.includes(Number(item.value)))
                            .map(( item, index ) => (
                                <span key={index}>{item.label}</span>
                            ))
                        }
                    </div>
                </div>

                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Бюджет в сутки</div>
                    <div className="campaign-view-details__value"
                         dangerouslySetInnerHTML={{ __html: prettifyMoneyValue(campaign.budgetDaily) }}
                    ></div>
                </div>

                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Бюджет на кампанию</div>
                    <div className="campaign-view-details__value"
                         dangerouslySetInnerHTML={{ __html: prettifyMoneyValue(campaign.budgetTotal) }}
                    ></div>
                </div>
            </div>
            <div className="campaign-view-details__video">
                <video controls playsInline>
                    <source src={campaign.content.url} type="video/mp4"/>
                </video>
            </div>
        </div>
    )
}

export default CampaignViewDetails;