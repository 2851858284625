import axios from 'axios';
import { IApiUsersInfoRequest } from "./users.requests";

class UsersApi {
    info = async (params?: IApiUsersInfoRequest) => {
        const { data } = await axios.get(`/users/`, { params });

        return data;
    }

    logout = async () => {
        const { data } = await axios.get(`/users/logout`);

        return data;
    }
}

const usersAPI = new UsersApi();
export default usersAPI;