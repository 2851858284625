import React from 'react';
import "./styles/TargetingDropdownItem.sass";
import classNames from "classnames";
import Checkbox from "../../../../../../../../UI/Checkbox/Checkbox";
import { ITargetingDropdownItemProps } from "./types/TargetingDropdownItem.types";
import { CheckboxState } from "../../../../../../../../UI/Checkbox/types/Checkbox.types";

const TargetingDropdownItem = ( props: ITargetingDropdownItemProps ) => {
    const { item, isSelected, onItemClick } = props;

    const onClick = (e: React.MouseEvent) => {
        e.preventDefault();

        if (typeof onItemClick === "function") {
            onItemClick(item);
        }
    }

    return (
        <div className="targeting-dropdown-item" onClick={onClick}>
            <Checkbox className={classNames("targeting-dropdown-item__checkbox")}
                      state={isSelected ? CheckboxState.CHECKED : CheckboxState.UNCHECKED}
            />
            <div className="targeting-dropdown-item__label">{item.label}</div>
        </div>
    )
}

export default TargetingDropdownItem;