import React, { useState } from "react";
import "./styles/GridTableCellActions.sass";
import DotsIcon from "../../../SvgIcons/DotsIcon";
import { useDetectClickOutside } from "react-detect-click-outside";
import { IGridTableCellActionsProps } from "./types/GridTableCellActions.types";
import { IGridRowAction } from "../../types/Grid.types";

const GridTableCellActions = (props: IGridTableCellActionsProps) => {
    const { actions } = props;

    const [isActionsOpened, setIsActionsOpened] = useState(false);
    const actionsRef = useDetectClickOutside({ onTriggered: () => setIsActionsOpened(false) });

    const onButtonToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
        setIsActionsOpened(!isActionsOpened);
    }

    const onActionClick = (action: IGridRowAction): void => {
        if (typeof action.handler === "function") {
            action.handler();
        }
        setIsActionsOpened(false);
    }

    return (
        <div className="grid-table-cell-actions" ref={actionsRef}>
            <button className="grid-table-cell-actions__button" onClick={onButtonToggle}>
                <DotsIcon />
            </button>

            {isActionsOpened && (
                <div className="grid-table-cell-actions__items">
                    {actions.map((action, index) => (
                        <div className="grid-table-cell-actions__item" key={index}
                             onClick={(e) => onActionClick(action)}
                        >{action.title}</div>
                    ))}
                </div>
            )}
        </div>
    )
};

export default GridTableCellActions;