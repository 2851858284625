import { atom, selector } from "recoil";
import { ToastType } from "../components/UI/Toast/types/Toast.types";

export interface IToastState {
    type: ToastType,
    message: string,
    isShown: boolean;
}

export const toastState = atom<IToastState>({
    key: "toastState",
    default: {
        type: ToastType.SUCCESS,
        message: "",
        isShown: false
    }
})

export const getIsToastShown = selector<boolean>({
    key: "isToastShown",
    get: ({ get }) => {
        const toast: IToastState = get(toastState);

        return toast.isShown;
    }
});

export const getToastType = selector<ToastType>({
    key: "toastType",
    get: ({ get }) => {
        const toast: IToastState = get(toastState);

        return toast.type;
    }
});

export const getToastMessage = selector<string>({
    key: "toastMessage",
    get: ({ get }) => {
        const toast: IToastState = get(toastState);

        return toast.message;
    }
});