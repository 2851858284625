export const toggleArrayItem = (items: any[], itemId: any) => {
    const existedIndex = items.indexOf(itemId);

    if (existedIndex !== -1) {
        items.splice(existedIndex, 1);
    } else {
        items.push(itemId);
    }

    return items;
}