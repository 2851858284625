import { atom, selector } from "recoil";

interface IProfileCompany {
    legalName: string;
    email: string;
    inn: string;
    address: string;
    businessArea?: string;
    website?: string;
    kpp?: string;
    companyForm?: string;
}

interface IProfilePerson {
    name: string;
    phone: string;
    email: string;
    balance: number;
}

export interface IProfileState {
    company?: IProfileCompany;
    person?: IProfilePerson;
}

export const profileState = atom<IProfileState>({
    key: "profileState",
    default: {
    }
});

export const getProfilePersonBalance = selector<number>({
    key: "profilePersonBalance",
    get: ({ get }) => {
        const person = get(profileState).person;

        return person ? person.balance : 0;
    }
})

export const profileCompanyFactory = (data: any): IProfileCompany => {
    const { companyForm, legalName, email, inn, address, businessArea, website, kpp } = data;

    return {
        companyForm,
        legalName,
        email,
        inn,
        address,
        businessArea,
        website,
        kpp
    }
}

export const profilePersonFactory = (data: any): IProfilePerson => {
    const { firstName, middleName, lastName, phone, email, balance } = data;

    return {
        name: [firstName, middleName, lastName].join(' '),
        phone,
        email,
        balance,
    }
}