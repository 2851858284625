import React from 'react';
import "./styles/LocalityTargetingItem.sass";
import { ILocalityTargetingItemProps } from "./types/LocalityTargetingItem.types";
import Checkbox from "../../../../../../../../UI/Checkbox/Checkbox";
import classNames from "classnames";
import ArrowDownIcon from "../../../../../../../../UI/SvgIcons/ArrowDownIcon";
import { CheckboxState } from "../../../../../../../../UI/Checkbox/types/Checkbox.types";

const LocalityTargetingItem = ( props: ILocalityTargetingItemProps ) => {
    const { item, selectedItems = [], expandedItems = [], level, onToggleItem, onSelectItem } = props;

    const onCheckboxClick = () => {
        selectItem(item.id);
    }

    const onToggleButtonClick = () => {
        expandItem(item.id);
    }

    const onToggleChildItem = (itemId: number) => {
        expandItem(itemId);
    }

    const onSelectChildItem = (itemId: number) => {
        selectItem(itemId);
    }

    const selectItem = (itemId: number) => {
        if (typeof onSelectItem === "function") {
            onSelectItem(itemId);
        }
    }

    const expandItem = (itemId: number) => {
        if (typeof onToggleItem === "function") {
            onToggleItem(itemId);
        }
    }

    const getIsItemExpanded = ( itemId: number ): boolean => {
        return expandedItems.includes(itemId);
    }

    const getCheckboxState = ( itemId: number ): CheckboxState => {
        return selectedItems.includes(itemId)
            ? CheckboxState.CHECKED
            : CheckboxState.UNCHECKED;
    }

    return (
        <div className={classNames(
            "locality-targeting-item",
            { "is-expanded": getIsItemExpanded(item.id) || level === 1 }
        )}>
            <div className="locality-targeting-item__header">
                <Checkbox
                    className={classNames("locality-targeting-item__checkbox")}
                    state={getCheckboxState(item.id)}
                    onClick={onCheckboxClick}
                />

                <div className="locality-targeting-item__title">{item.text}</div>

                {(item.nodes.length > 0 && level > 1) && (
                    <button type="button"
                            className="locality-targeting-item__toggle"
                            onClick={onToggleButtonClick}
                    >
                        <ArrowDownIcon/>
                    </button>
                )}
            </div>

            {item.nodes.length > 0 && (
                <div className={classNames("locality-targeting-item__children", `level-${level}`)}>
                    {item.nodes.map(node => (
                        <LocalityTargetingItem
                            key={node.id}
                            item={node}
                            selectedItems={selectedItems}
                            expandedItems={expandedItems}
                            level={level + 1}
                            onToggleItem={onToggleChildItem}
                            onSelectItem={onSelectChildItem}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

export default LocalityTargetingItem;