import React from 'react';
import "./styles/CampaignAdvertiserMarkModal.sass";
import Modal from 'components/UI/Modal/Modal';

const CampaignAdvertiserMarkModal = () => {
    return (
        <Modal justifyContent="center" className="campaign-advertiser-mark-modal">
            <div className="campaign-advertiser-mark-modal__content">
                <div className="campaign-advertiser-mark-modal__header">
                    <div className="campaign-advertiser-mark-modal__title">Пример маркировки о рекламодателе</div>
                </div>

                <div className="campaign-advertiser-mark-modal__body">
                    <img src="/assets/img/campaign/advertiser/example.png" alt=""/>
                </div>
            </div>
        </Modal>
    )
}

export default CampaignAdvertiserMarkModal;
