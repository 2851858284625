import React, { useCallback } from 'react';
import "./styles/ClientsReleaseModal.sass";
import Modal from 'components/UI/Modal/Modal';
import Button from "../../UI/Button/Button";
import { useRecoilState, useRecoilValue } from "recoil";
import { getModalOptions, modalState } from "../../../store/Modal.recoil";
import { IClientsDeleteModalOptions } from "./types/ClientsReleaseModal.types";

const ClientsReleaseModal = () => {
    const [, setModal] = useRecoilState(modalState);
    const modalOptions = useRecoilValue<IClientsDeleteModalOptions>(getModalOptions);
    const { client } = modalOptions;

    const closeModal = useCallback(() => {
        setModal({
            type: null,
            options: {},
            isOpened: false
        })
    }, [setModal]);

    const onCancelButtonClick = () => {
        closeModal();
    }

    const onReleaseButtonClick = () => {
        closeModal();
    }


    return (
        <Modal justifyContent="center" className="clients-release-modal">
            <div className="clients-release-modal__content">
                <div className="clients-create-modal__header">
                    <div className="clients-create-modal__title">Отвязать от аккаунта</div>
                </div>
                <div className="clients-release-modal__body">
                    <div className="clients-release-modal__text">
                        Данные клиента будут удалены с вашего аккаунта. Сумма со счета будет переведена на общий счет
                    </div>
                </div>
                <div className="clients-release-modal__footer">
                    <div className="clients-release-modal__actions">
                        <Button isSecondary title="Отмена" onClick={onCancelButtonClick} />
                        <Button title="Отвязать" onClick={onReleaseButtonClick} />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ClientsReleaseModal;