import React from 'react';
import "./styles/TargetingDropdownTag.sass";
import CrossIcon from "../../../../../../../../UI/SvgIcons/CrossIcon";
import { ITargetingDropdownTagProps } from "./types/TargetingDropdownTag.types";

const TargetingDropdownTag = ( props: ITargetingDropdownTagProps ) => {
    const { item, onTagClick } = props;

    const onClick = (e: React.MouseEvent) => {
        e.preventDefault();

        if (typeof onTagClick === "function") {
            onTagClick(item);
        }
    }

    return (
        <div className="targeting-dropdown-tag" onClick={onClick}>
            <div className="targeting-dropdown-tag__label">{item.label}</div>
            <button type="button" className="targeting-dropdown-tag__close">
                <CrossIcon />
            </button>
        </div>
    )
}

export default TargetingDropdownTag;