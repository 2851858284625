import React, { useId } from 'react';
import './styles/TextInput.sass';
import { ITextInputProps } from "./types/TextInput.types";
import classNames from "classnames";

const TextInput = (props: ITextInputProps) => {
    const {
        label,
        placeholder,
        inputType,
        className,
        isRequired,
        isReadOnly,
        name,
        value,
        hasError,
        errorMessage,
        onChange
    } = props;

    const inputId = useId();

    return (
        <div className={classNames(
            "text-input__wrapper",
            className,
            { 'has-error': hasError }
        )}>
            {label && (
                <label htmlFor={inputId} className={classNames("text-input__label", { 'is-required': isRequired })}>
                    {label}
                </label>
            )}
            <input id={inputId}
                   name={name}
                   type={inputType || "text"}
                   className="text-input__input"
                   autoComplete="off"
                   placeholder={placeholder}
                   readOnly={isReadOnly}
                   defaultValue={value}
                   onChange={onChange}
            />
            { hasError && errorMessage && (<div className="text-input__error">{errorMessage}</div>) }
        </div>
    )
}

export default TextInput;