import React, { ChangeEvent, useState } from 'react';
import "./styles/ClientsCreateModal.sass";
import API from "../../../api";
import Modal from 'components/UI/Modal/Modal';
import Button from "../../UI/Button/Button";
import TextInput from "../../UI/Inputs/TextInput/TextInput";
import { ClientFormType } from "../../../models/enums";
import Pill from "../../UI/Pill/Pill";
import classNames from "classnames";
import SearchInput from "../../UI/Inputs/SearchInput/SearchInput";
import { useSetRecoilState } from "recoil";
import { modalState, ModalType } from "../../../store/Modal.recoil";
import { ICompanySaveRequest } from "../../../api/services/companies/companies.request";
import { IClientsCreateModalErrors } from "./types/ClientsCreateModal.types";
import { toastState } from "../../../store/Toast.recoil";
import { ToastType } from "../../UI/Toast/types/Toast.types";

const ClientsCreateModal = () => {
    const setModal = useSetRecoilState(modalState);
    const setToast = useSetRecoilState(toastState);

    const [legalType, setLegalType] = useState(ClientFormType.UL);
    const [formData, setFormData] = useState<ICompanySaveRequest>({
        formType: ClientFormType.UL,
        firstName: "",
        middleName: "",
        lastName: "",
        legalName: "",
        inn: "",
        contract: "",
        comment: ""
    });
    const [formErrors, setFormErrors] = useState<IClientsCreateModalErrors>({});

    const isPillActive = ( selector: ClientFormType ): boolean => {
        return legalType === selector;
    }

    const changeLegalType = ( value: ClientFormType ): void => {
        setLegalType(value);
        setFormData({
            formType: value,
            firstName: "",
            middleName: "",
            lastName: "",
            legalName: "",
            inn: "",
            contract: "",
            comment: ""
        });
        setFormErrors({});
    }

    const onNonNumericValueChanged = ( e: ChangeEvent<HTMLInputElement> ) => {
        const { data } = e.nativeEvent as InputEvent;
        if (/[0-9]/.test(String(data))) {
            onValueChanged(e);
        }
    }

    const onValueChanged = ( e: any ) => {
        const newFormData = {
            ...formData,
            [e.target.name]: e.target.value
        }

        setFormData(newFormData);
    }

    const renderCompanySuggestion = ( item: any ): string => {
        const { data } = item;

        return data.name.short_with_opf + ', ИНН: ' + data.inn;
    }

    const selectCompanySuggestion = ( item: any ) => {
        const { data } = item;

        const newFormData = {
            ...formData,
            legalName: data.name.short_with_opf,
            inn: data.inn
        }

        setFormData(newFormData);
    }

    const isFormDataValid = (): boolean => {
        let errors: IClientsCreateModalErrors = {};

        if (formData.formType === ClientFormType.FL) {
            if (formData.lastName?.length === 0) {
                errors.lastName = "Необходимо заполнить «Фамилия».";
            }

            if (formData.firstName?.length === 0) {
                errors.firstName = "Необходимо заполнить «Имя».";
            }
        } else {
            if (formData.legalName?.length === 0) {
                errors.legalName = "Необходимо заполнить «Название компании».";
            }

            if (formData.inn?.length === 0) {
                errors.inn = "Необходимо заполнить «ИНН».";
            }
        }

        if (formData.contract?.length === 0) {
            errors.contract = "Необходимо заполнить «Номер договора».";
        }

        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    }

    const onCreateButtonClick = () => {
        if (!isFormDataValid()) return;

        API.clients
            .create(formData)
            .then(( { data: clientData } ) => {
                setModal({
                    type: ModalType.createClient,
                    isOpened: false
                });
            })
            .catch(({ response }) => {
                const { data } = response;
                const { errors, message } = data;

                setFormErrors(errors);

                setToast({
                    type: ToastType.ERROR,
                    message,
                    isShown: true
                });
            })
    }

    return (
        <Modal justifyContent="center" className="clients-create-modal">
            <div className="clients-create-modal__content">
                <div className="clients-create-modal__header">
                    <div className="clients-create-modal__title">Создание клиента</div>
                </div>
                <div className="clients-create-modal__body">
                    <div className="clients-create-modal__types">
                        <Pill
                            className={classNames({ 'active': isPillActive(ClientFormType.UL) })}
                            title="Юр. лицо"
                            onClick={( e ) => changeLegalType(ClientFormType.UL)}
                        />
                        <Pill
                            className={classNames({ 'active': isPillActive(ClientFormType.IP) })}
                            title="ИП"
                            onClick={( e ) => changeLegalType(ClientFormType.IP)}
                        />
                        <Pill
                            className={classNames({ 'active': isPillActive(ClientFormType.FL) })}
                            title="Физ. лицо"
                            onClick={( e ) => changeLegalType(ClientFormType.FL)}
                        />
                    </div>
                    <div className="clients-create-modal__inputs">
                        {legalType === ClientFormType.FL && (
                            <>
                                <TextInput
                                    isRequired
                                    name="lastName"
                                    label="Фамилия"
                                    placeholder="Введите фамилию"
                                    value={formData.lastName}
                                    hasError={!!formErrors?.lastName}
                                    errorMessage={formErrors?.lastName}
                                    onChange={onValueChanged}
                                />

                                <TextInput
                                    isRequired
                                    name="firstName"
                                    label="Имя"
                                    placeholder="Введите имя"
                                    value={formData.firstName}
                                    hasError={!!formErrors?.firstName}
                                    errorMessage={formErrors?.firstName}
                                    onChange={onValueChanged}
                                />

                                <TextInput
                                    name="middleName"
                                    label="Отчество"
                                    placeholder="Введите отчество"
                                    value={formData.middleName}
                                    onChange={onValueChanged}
                                />

                                <TextInput
                                    isRequired
                                    name="inn"
                                    label="ИНН"
                                    placeholder="Введите ИНН"
                                    value={formData.inn}
                                    hasError={!!formErrors?.inn}
                                    errorMessage={formErrors?.inn}
                                    onChange={onValueChanged}
                                />
                            </>

                        )}

                        {[ClientFormType.UL, ClientFormType.IP].includes(legalType) && (
                            <>
                                <SearchInput
                                    isRequired
                                    name="legalName"
                                    label="Название компании"
                                    placeholder="Введите название кампании"
                                    value={formData.legalName}
                                    hasError={!!formErrors?.legalName}
                                    errorMessage={formErrors?.legalName}
                                    suggestionApi={API.suggestion.company}
                                    suggestionRenderResult={renderCompanySuggestion}
                                    suggestionClickResult={selectCompanySuggestion}
                                />

                                <SearchInput
                                    isRequired
                                    name="inn"
                                    label="ИНН"
                                    placeholder="Введите ИНН"
                                    value={formData.inn}
                                    hasError={!!formErrors?.inn}
                                    errorMessage={formErrors?.inn}
                                    suggestionApi={API.suggestion.company}
                                    suggestionRenderResult={renderCompanySuggestion}
                                    suggestionClickResult={selectCompanySuggestion}
                                />
                            </>
                        )}

                        <div className="clients-create-modal__accent">Информация о договоре</div>
                        <TextInput
                            isRequired
                            name="contract"
                            label="Номер договора"
                            placeholder="Введите номер договора"
                            value={formData.contract}
                            hasError={!!formErrors?.contract}
                            errorMessage={formErrors?.contract}
                            onChange={onValueChanged}
                        />

                        <TextInput
                            name="comment"
                            label="Комментарий"
                            placeholder="Введите комментарий"
                            value={formData.comment}
                            onChange={onValueChanged}
                        />
                    </div>
                </div>
                <div className="clients-create-modal__footer">
                    <div className="clients-create-modal__actions">
                        <Button title="Создать клиента" onClick={onCreateButtonClick}/>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ClientsCreateModal;