import React, { useEffect, useState } from 'react';
import TargetingLocalities from "./components/TargetingLocalities/TargetingLocalities";
import API from "../../../../../api";
import Info from "../../../../UI/Info/Info";
import TargetingDropdown from "./components/TargetingDropdown/TargetingDropdown";
import Chips from "../../../../UI/Chips/Chips";
import TargetingDevices from "./components/TargetingDevices/TargetingDevices";
import { ChipItemValue, IChipItem } from "../../../../UI/Chips/components/types/ChipsItem.types";
import { IApiCampaignLocality } from "../../../../../api/services/campaigns/campaigns.response";
import { ICampaignFormTabTargetingProps, ICampaignFormTargetingData } from "./types/CampaignFormTabTargeting.props";
import {
    ITargetingDropdownItem,
    TargetingDropdownItemValue
} from "./components/TargetingDropdown/types/TargetingDropdown.types";

const CampaignFormTabTargeting = ( props: ICampaignFormTabTargetingProps ) => {
    const { values: formValues, onTabDataChanged } = props;

    const [localities, setLocalities] = useState<IApiCampaignLocality[]>([]);
    const [intervals, setIntervals] = useState<ITargetingDropdownItem[]>([]);
    const [genres, setGenres] = useState<IChipItem[]>([]);
    const [ages, setAges] = useState<IChipItem[]>([]);
    const [genders, setGenders] = useState<IChipItem[]>([]);

    const [formData, setFormData] = useState<ICampaignFormTargetingData>({
        regions: [],
        intervals: formValues.intervals,
        genres: formValues.genres,
        devices: formValues.devices,
        age: formValues.age,
        sex: formValues.sex
    });

    // TODO: вынести справочники в общий стейт для всего аппа
    useEffect(() => {
        API.campaigns.localities()
            .then(( { data } ) => {
                setLocalities(data)
            });

        API.campaigns.intervals()
            .then(( { data } ) => {
                const intervals: IChipItem[] = Object.entries(data)
                    .map(( [key, value] ) => {
                        return {
                            label: String(value),
                            value: key
                        };
                    });
                setIntervals(intervals);
            });

        API.campaigns.genres()
            .then(( { data } ) => {
                const genres: IChipItem[] = Object.entries(data)
                    .map(( [key, value] ) => {
                        return {
                            label: String(value),
                            value: key
                        };
                    });

                setGenres(genres);
            });

        API.campaigns.ages()
            .then(( { data } ) => {
                const ages: IChipItem[] = Object.entries(data)
                    .map(( [key, value] ) => {
                        return {
                            label: String(value),
                            value: key
                        };
                    });

                setAges(ages);
            });

        API.campaigns.genders()
            .then(( { data } ) => {
                setGenders(data.map(
                    ( value, index ) => {
                        return {
                            label: String(value),
                            value: index
                        }
                    }
                ));
            })
    }, []);

    const onRegionsItemsChanged = ( regions: number[] ) => {
        updateFormData({ ...formData, regions });
    }

    const onIntervalsItemsChanged = ( values: TargetingDropdownItemValue[] ) => {
        updateFormData({
            ...formData,
            intervals: values.map(( value ) => Number(value))
        });
    }

    const onGenresItemsChanged = ( values: ChipItemValue[] ) => {
        updateFormData({
            ...formData,
            genres: values.map(( value ) => String(value))
        });
    }

    const onDeviceItemsChanged = ( devices: string[] ) => {
        updateFormData({
            ...formData,
            devices
        });
    }

    const onAgesItemsChanged = ( values: TargetingDropdownItemValue[] ) => {
        updateFormData({
            ...formData,
            age: values.map(( value ) => Number(value))
        });
    }

    const onGendersItemsChanged = ( values: ChipItemValue[] ) => {
        updateFormData({
            ...formData,
            sex: values.map(( value ) => Number(value))
        });
    }

    const updateFormData = ( formData: ICampaignFormTargetingData ) => {
        setFormData({ ...formData });

        if (typeof onTabDataChanged === "function") {
            onTabDataChanged({ ...formData });
        }
    }

    useEffect(() => {

        setFormData({
            regions: [],
            intervals: formValues.intervals,
            genres: formValues.genres,
            devices: formValues.devices,
            age: formValues.age,
            sex: formValues.sex
        });
    }, [formValues]);

    return (
        <div className="campaign-create-form__fields">
            <div className="campaign-create-form__field">
                <TargetingLocalities
                    items={localities}
                    selectedItems={formData.regions}
                    onSelectedItemsChanged={onRegionsItemsChanged}
                />
            </div>

            <div className="campaign-create-form__field">
                <TargetingDropdown
                    label="Время показа рекламы"
                    placeholder="Выберите время показа рекламы"
                    items={intervals}
                    selectedValues={formData.intervals}
                    onSelectedItemsChanged={onIntervalsItemsChanged}
                />

                <Info className="campaign-create-form__info">
                    Трансляция ведется в часовом поясе выбранных городов (по местному времени). В случае если Вы не
                    выбрали слоты по времени, реклама может быть показана в любой промежуток времени в указанные
                    даты.
                </Info>
            </div>

            <div className="campaign-create-form__field">
                <Chips
                    label="Выберите жанры телеканалов"
                    items={genres}
                    selectedValues={formData.genres}
                    onSelectedItemsChanged={onGenresItemsChanged}
                />
                <Info className="campaign-create-form__info">
                    В случае если не выбран жанр контента кампании, то трансляция рекламы может производиться на
                    любых каналах, без учета жанра.
                </Info>
            </div>

            <div className="campaign-create-form__field">
                <TargetingDevices
                    label="Выберите тип рекламы"
                    selectedValues={formData.devices}
                    onSelectedItemsChanged={onDeviceItemsChanged}
                />
            </div>

            <div className="campaign-create-form__field">
                <TargetingDropdown
                    label="Выберите возраст"
                    placeholder="Выберите возраст"
                    items={ages}
                    selectedValues={formData.age}
                    onSelectedItemsChanged={onAgesItemsChanged}
                />
            </div>

            <div className="campaign-create-form__field">
                <Chips
                    label="Выберите пол"
                    items={genders}
                    selectedValues={formData.sex}
                    onSelectedItemsChanged={onGendersItemsChanged}
                />
            </div>
        </div>
    );
}

export default CampaignFormTabTargeting;