import React from 'react';
import "./styles/MobileMenu.sass";
import classNames from "classnames";
import { IMobileMenuProps } from "./types/MobileMenu.props";
import Sidebar from "../Sidebar/Sidebar";

const MobileMenu = (props: IMobileMenuProps) => {
    const { className } = props;

    return (
        <div className={classNames('mobile-menu', className)}>
            <Sidebar withFooter className="mobile-menu__sidebar" />
        </div>
    );
}

export default MobileMenu;
