import axios from 'axios';
import { IProfileSaveRequest } from "./requests/profileAPI.request";

class ProfileAPI {
    update = (values: IProfileSaveRequest) => {
        return axios.post(`/profile/update`, values);
    }
}

const profileAPI = new ProfileAPI();
export default profileAPI;