import { atom, selector } from "recoil";

export interface IUserState {
    id?: number;
    email?: string,
    phone?: string,
    firstName?: string,
    middleName?: string,
    lastName?: string,
    balance?: string
}

export const userState = atom<IUserState>({
    key: "userState",
    default: {
    }
});

export const getUserId = selector<undefined | number>({
    key: "userId",
    get: ({ get }) => {
        const { id } = get(userState);

        return id;
    }
});

export const getUserEmail = selector<undefined | string>({
    key: "userEmail",
    get: ({ get }) => {
        const { email } = get(userState);

        return email;
    }
});

export const getUserPhone = selector<undefined | string>({
    key: "userPhone",
    get: ({ get }) => {
        const { phone } = get(userState);

        return phone;
    }
});

export const getUserFullName = selector<undefined | string>({
    key: "userFullName",
    get: ({ get }) => {
        const { firstName, middleName, lastName } = get(userState);

        return [lastName, firstName, middleName ].join(' ');
    }
});

export const getUserBalance = selector<string>({
    key: "userBalance",
    get: ({ get }) => {
        const { balance } = get(userState);

        return balance || '0';
    }
});