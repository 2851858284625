import { IClientsListCellNameProps } from "./types/ClientsListCellName.types";
import "./styles/ClientsListCellName.sass";
import { useSetRecoilState } from "recoil";
import { clientsState } from "../../../../../store/Clients.recoil";
import { useNavigate } from "react-router-dom";
import { profileCompanyFactory, profilePersonFactory, profileState } from "../../../../../store/Profile.recoil";
import API from "../../../../../api";

const ClientsListCellName = ( props: IClientsListCellNameProps ) => {
    const { item } = props;

    const navigate = useNavigate();

    const setProfileState = useSetRecoilState(profileState);
    const setClientsState = useSetRecoilState(clientsState);

    const handleClick = () => {
        Promise.all([
            API.users.info({ id: item.userId }),
            API.companies.info({ id: item.companyId }),
        ]).then(([userResponse, companyResponse]) => {
            const { data: user } = userResponse;
            const { data: company } = companyResponse;

            setProfileState((state) => {
                return {
                    ...state,
                    person: profilePersonFactory(user),
                    company: profileCompanyFactory(company),
                }
            });
            setClientsState((state) => {
                return {
                    ...state,
                    currentClient: {
                        id: item.id,
                        name: item.name
                    }
                }
            });

            navigate("/campaigns");
        });
    }

    return (
        <div className="clients-list-cell-name">
            <button className="clients-list-cell-name__button" onClick={handleClick}>{item.name}</button>
        </div>
    )
}

export default ClientsListCellName;