import React from 'react';
import './styles/CampaignsListEmpty.sass';
import Button from "../../../../UI/Button/Button";
import { useNavigate } from "react-router-dom";

const CampaignsListEmpty = () => {
    const navigate = useNavigate();

    const onButtonClick = (): void => {
        navigate('/campaigns/create');
    }

    return (
        <div className="campaigns-list-empty">
            <p className="campaigns-list-empty__text">У вас пока нет запущенных кампаний</p>
            <Button title="Создать кампанию" className="campaigns-list-empty__button" onClick={onButtonClick} />
        </div>
    )
}

export default CampaignsListEmpty;