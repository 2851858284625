import { atom } from "recoil";

export enum PaymentType {
    CARD = 'yookassa::bank_card',
    SBP = 'yookassa::sbp',
    B2B = 'yookassa::b2b_sberbank',
    INVOICE = 'invoice'
}

export interface IPaymentState {
    type: PaymentType,
    amount: number;
}

export const paymentState = atom<IPaymentState>({
    key: "paymentState",
    default: {
        type: PaymentType.INVOICE,
        amount: 0
    }
})