import React, { Suspense, useEffect } from 'react';
import "../styles/Page.sass";
import PageTop from "../../../components/PageTop/PageTop";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import { setDocumentTitle } from "../../../helpers/document.helpers";
import DocumentsListEmpty
    from "../../../components/Documents/DocumentsList/components/DocumentsListEmpty/DocumentsListEmpty";

const DocumentsPage = () => {
    useEffect(() => {
        setDocumentTitle('Документы');
    }, []);

    return (
        <div className="page documents">
            <PageTop className="documents__top">
                <PageTitle
                    className="documents__title"
                    title="Документы"
                />
            </PageTop>

            <Suspense>
                <DocumentsListEmpty />
            </Suspense>
        </div>
    );
}

export default DocumentsPage;
