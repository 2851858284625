import React from 'react';
import './styles/FinancesAddModal.sass';
import Modal from "../../UI/Modal/Modal";
import FinancesAddItem from "./FinancesAddItem/FinancesAddItem";
import BankCardIcon from "../../UI/SvgIcons/payments/BankCardIcon";
import SBPIcon from "../../UI/SvgIcons/payments/SBPIcon";
import SberBusinessIcon from "../../UI/SvgIcons/payments/SberBusinessIcon";
import GetInvoiceIcon from "../../UI/SvgIcons/payments/GetInvoiceIcon";
import { IFinancesAddItemProps } from "./FinancesAddItem/types/FinancesAddItem.types";
import { paymentState, PaymentType } from "../../../store/Payment.recoil";
import { useRecoilState, useSetRecoilState } from "recoil";
import { modalState } from "../../../store/Modal.recoil";
import API from "../../../api";
import { toastState } from "../../../store/Toast.recoil";
import { ToastType } from "../../UI/Toast/types/Toast.types";

const paymentsMethods: IFinancesAddItemProps[] = [
    {
        id: "card",
        icon: <BankCardIcon />,
        title: "Банковская карта",
        code: PaymentType.CARD
    },
    {
        id: "sbp",
        icon: <SBPIcon />,
        title: "Система быстрых платежей",
        code: PaymentType.SBP
    },
    {
        id: "business",
        icon: <SberBusinessIcon />,
        title: "СберБизнес",
        code: PaymentType.B2B
    },
    {
        id: "invoice",
        icon: <GetInvoiceIcon />,
        title: "Запросить счёт",
        code: PaymentType.INVOICE
    }
]

const FinancesAddModal = () => {
    const [ paymentData, setPaymentData] = useRecoilState(paymentState);
    const setModalState = useSetRecoilState(modalState);
    const setToast = useSetRecoilState(toastState);

    const onPaymentMethodClick = (paymentsMethod: IFinancesAddItemProps): void => {
        const { code } = paymentsMethod;
        const newPaymentData = { ...paymentData, type: code };
        setPaymentData(newPaymentData);

        API.payments.create(newPaymentData)
            .then(({ data }) => {
                const { message, redirectUrl } = data;
                if (redirectUrl) {
                    window.location.replace(redirectUrl);
                } else {
                    setToast({
                        type: ToastType.SUCCESS,
                        message,
                        isShown: true,
                    })
                }

                setModalState({
                    type: null,
                    isOpened: false,
                    options: {}
                })
            })
            .catch((error) => {
                setToast({
                    type: ToastType.ERROR,
                    message: 'Ошибка. Попробуйте позже.',
                    isShown: true,
                })
            })
    }

    return (
        <Modal justifyContent="center">
            <div className="finances-add">
                <div className="finances-add__header">
                    <div className="finances-add__title">Пополнение баланса</div>
                </div>
                <div className="finances-add__body">
                    <div className="finances-add__items">
                        {paymentsMethods.map(paymentsMethod => (
                            <FinancesAddItem
                                key={paymentsMethod.id}
                                className="finances-add__item"
                                id={paymentsMethod.id}
                                title={paymentsMethod.title}
                                icon={paymentsMethod.icon}
                                code={paymentsMethod.code}
                                onClick={(e) => onPaymentMethodClick(paymentsMethod)}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default FinancesAddModal;