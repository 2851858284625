import React, { useCallback } from 'react';
import "./styles/ClientsTransferModal.sass";
import Modal from 'components/UI/Modal/Modal';
import Button from "../../UI/Button/Button";
import { useRecoilState, useRecoilValue } from "recoil";
import { getModalOptions, modalState } from "../../../store/Modal.recoil";
import { IClientsDeleteModalOptions } from "./types/ClientsTransferModal.types";
import TextInput from "../../UI/Inputs/TextInput/TextInput";

const ClientsTransferModal = () => {
    const [, setModal] = useRecoilState(modalState);
    const modalOptions = useRecoilValue<IClientsDeleteModalOptions>(getModalOptions);
    const { client } = modalOptions;

    const closeModal = useCallback(() => {
        setModal({
            type: null,
            options: {},
            isOpened: false
        })
    }, [setModal]);

    const onCancelButtonClick = () => {
        closeModal();
    }

    const onReleaseButtonClick = () => {
        closeModal();
    }


    return (
        <Modal justifyContent="center" className="clients-transfer-modal">
            <div className="clients-transfer-modal__content">
                <div className="clients-create-modal__header">
                    <div className="clients-create-modal__title">Передать аккаунт клиента</div>
                </div>
                <div className="clients-transfer-modal__body">
                    <TextInput
                        label="ID аккаунта клиента"
                        placeholder="Введите ID"
                    />
                </div>
                <div className="clients-transfer-modal__footer">
                    <div className="clients-transfer-modal__actions">
                        <Button isSecondary title="Отмена" onClick={onCancelButtonClick} />
                        <Button title="Передать доступ" onClick={onReleaseButtonClick} />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ClientsTransferModal;