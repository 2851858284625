import React from "react";

export enum ToastType {
    SUCCESS = "success",
    ERROR = "error",
}

export interface IToastProps extends React.HTMLAttributes<HTMLDivElement> {
    type: ToastType;
    message: string;
}