import React from "react";
import { ISvgIconProps } from "./types/SvgIcon.types";

const DotsIcon = (props: ISvgIconProps) => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 11C5.17157 11 4.5 11.6716 4.5 12.5C4.5 13.3284 5.17157 14 6 14C6.82843 14 7.5 13.3284 7.5 12.5C7.5 11.6716 6.82843 11 6 11Z"
                fill="#8D99A4"/>
            <path
                d="M10.5 12.5C10.5 11.6716 11.1716 11 12 11C12.8284 11 13.5 11.6716 13.5 12.5C13.5 13.3284 12.8284 14 12 14C11.1716 14 10.5 13.3284 10.5 12.5Z"
                fill="#8D99A4"/>
            <path
                d="M16.5 12.5C16.5 11.6716 17.1716 11 18 11C18.8284 11 19.5 11.6716 19.5 12.5C19.5 13.3284 18.8284 14 18 14C17.1716 14 16.5 13.3284 16.5 12.5Z"
                fill="#8D99A4"/>
        </svg>
    );
};

export default DotsIcon;