import React from "react";
import "./styles/ClientPlate.sass";
import { IClientPlateProps } from "./types/ClientPlate.types";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { profileCompanyFactory, profilePersonFactory, profileState } from "../../../../../store/Profile.recoil";
import { clientsState } from "../../../../../store/Clients.recoil";
import API from "../../../../../api";

const ClientPlate = ( props: IClientPlateProps) => {
    const { item } = props;

    const navigate = useNavigate();

    const setProfileState = useSetRecoilState(profileState);
    const setClientsState = useSetRecoilState(clientsState);

    const handleClick = () => {
        Promise.all([
            API.users.info({ id: item.userId }),
            API.companies.info({ id: item.companyId }),
        ]).then(([userResponse, companyResponse]) => {
            const { data: user } = userResponse;
            const { data: company } = companyResponse;

            setProfileState((state) => {
                return {
                    ...state,
                    person: profilePersonFactory(user),
                    company: profileCompanyFactory(company),
                }
            });
            setClientsState((state) => {
                return {
                    ...state,
                    currentClient: {
                        id: item.id,
                        name: item.name
                    }
                }
            });

            navigate("/profile");
        });
    }

    return (
        <div className="client-plate">
            <div className="client-plate__title">
                <div className="client-plate__label">Название</div>
                <div className="client-plate__value">
                    <button className="client-plate__button" onClick={handleClick}>
                        {item.name}
                    </button>
                </div>
            </div>

            <div className="client-plate__status">
                <div className="client-plate__label">Статус</div>
                <div className="client-plate__value">Активно</div>
            </div>

            <div className="client-plate__statistics">
                <div className="client-plate__stat">
                    <div className="client-plate__label">Показы</div>
                    <div className="client-plate__value">123456</div>
                </div>

                <div className="client-plate__stat">
                    <div className="client-plate__label">Клики</div>
                    <div className="client-plate__value">123456</div>
                </div>

                <div className="client-plate__stat">
                    <div className="client-plate__label">Дата начала</div>
                    <div className="client-plate__value">01.02.0003</div>
                </div>

                <div className="client-plate__stat">
                    <div className="client-plate__label">Дата окончания</div>
                    <div className="client-plate__value">31.02.0003</div>
                </div>
            </div>
        </div>
    )
};

export default ClientPlate;