import React, { useEffect, useState } from 'react';
import "../styles/Page.sass";
import "./styles/Finances.sass";
import PageTop from "../../../components/PageTop/PageTop";
import FinancesStat from "../../../components/Finances/FinancesStat/FinancesStat";
import FinancesRedistribution from "../../../components/Finances/FinancesRedistribution/FinancesRedistribution";
import FinancesHistory from "../../../components/Finances/FinancesHistory/FinancesHistory";
import { setDocumentTitle } from "../../../helpers/document.helpers";
import { useRecoilValue } from "recoil";
import { getClientsIsLoggedToClient } from "../../../store/Clients.recoil";
import API from "../../../api";
import { IApiClientsListResponse } from "../../../api/services/clients/clients.response";

const FinancesPage = () => {
    const isLoggedToClient = useRecoilValue(getClientsIsLoggedToClient);
    const [isClientsExists, setIsClientsExists] = useState<boolean>(false);

    useEffect(() => {
        setDocumentTitle('Пополнение бюджета');

        API.clients.list()
            .then(( response: IApiClientsListResponse ) => {
                const { count } = response;
                setIsClientsExists(count > 0);
            })
    }, []);

    return (
        <div className="page finances">
            <PageTop className="finances__top">
                <FinancesStat />
                {!isLoggedToClient && isClientsExists && (<FinancesRedistribution />)}
            </PageTop>

            <FinancesHistory />
        </div>
    );
}

export default FinancesPage;
