import React from "react";
import { IGridTableCellDataProps } from "./types/GridTableCellData.types";
import "./styles/GridTableCellData.sass";

const GridTableCellData = (props: IGridTableCellDataProps) => {
    const { item } = props;

    return (
        <div className="grid-table-cell-data">{item.value}</div>
    )
};

export default GridTableCellData;