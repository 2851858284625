import React, { useEffect } from 'react';
import "../styles/Page.sass";
import "./styles/CampaignCreatePage.sass";
import PageTop from "../../../components/PageTop/PageTop";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import CampaignForm from "../../../components/Campaign/CampaignForm/CampaignForm";
import { setDocumentTitle } from "../../../helpers/document.helpers";
import { useParams } from "react-router-dom";

const CampaignEditPage = () => {
    const { id: campaignId } = useParams();

    useEffect(() => {
        setDocumentTitle('Создание рекламной кампании');
    }, [])

    return (
        <div className="page campaign-create">
            <PageTop className="campaign-create__top">
                <PageTitle
                    className="campaign-create__title"
                    title="Создание рекламной кампании"
                />
            </PageTop>

            <CampaignForm campaignId={campaignId} />
        </div>
    );
}

export default CampaignEditPage;
