import React from "react";
import { ISvgIconProps } from "./types/SvgIcon.types";

const CopyIcon = (props: ISvgIconProps) => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 13.4V17.6C16 21.1 14.6 22.5 11.1 22.5H6.9C3.4 22.5 2 21.1 2 17.6V13.4C2 9.9 3.4 8.5 6.9 8.5H11.1C14.6 8.5 16 9.9 16 13.4Z"
                fill="#8D99A4"/>
            <path
                d="M17.0998 2.5H12.8998C9.81668 2.5 8.37074 3.59409 8.06951 6.23901C8.00649 6.79235 8.46476 7.25 9.02167 7.25H11.0998C15.2998 7.25 17.2498 9.2 17.2498 13.4V15.4781C17.2498 16.035 17.7074 16.4933 18.2608 16.4303C20.9057 16.129 21.9998 14.6831 21.9998 11.6V7.4C21.9998 3.9 20.5998 2.5 17.0998 2.5Z"
                fill="#8D99A4"/>
        </svg>
    );
};

export default CopyIcon;