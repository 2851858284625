import React, { useEffect } from 'react';
import "../styles/Page.sass";
import PageTop from "components/PageTop/PageTop";
import PageTitle from "components/UI/PageTitle/PageTitle";
import ProfileForm from "components/Profile/ProfileForm/ProfileForm";
import { setDocumentTitle } from "../../../helpers/document.helpers";

const ProfilePage = () => {
    useEffect(() => {
        setDocumentTitle('Профиль');
    }, []);

    return (
        <div className="page profile-page">
            <PageTop className="profile-page__top">
                <PageTitle
                    className="profile-page__title"
                    title="Основная информация"
                />
            </PageTop>

            <ProfileForm />
        </div>
    );
}

export default ProfilePage;
