const ACCESS_TOKEN_KEY = '_token';

export const storeAccessToken = (): void => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('ak');

    if (accessToken) {
        localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    }
}

export const getAccessToken = (): null | string => {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export const removeAccessToken = (): void => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
}