import React from "react";
import { ISvgIconProps } from "./types/SvgIcon.types";

const ReturnIcon = (props: ISvgIconProps) => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.5 13L3.5 9M3.5 9L7.5 5M3.5 9L16.5 9C19.2614 9 21.5 11.2386 21.5 14C21.5 16.7614 19.2614 19 16.5 19L11.5 19"
                stroke="#4E5E6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default ReturnIcon;