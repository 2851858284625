import React, { useState } from 'react';
import './styles/FinancesAddAmountModal.sass';
import Modal from "../../UI/Modal/Modal";
import TextInput from "../../UI/Inputs/TextInput/TextInput";
import Button from "../../UI/Button/Button";
import { useSetRecoilState } from "recoil";
import { paymentState } from "../../../store/Payment.recoil";
import { modalState, ModalType } from "../../../store/Modal.recoil";
import {
    IFinancesAddAmountModalFormData,
    IFinancesAddAmountModalFormErrors
} from "./types/FinancesAddAmountModal.types";

const FinancesAddAmountModal = ( props: any) => {
    const setPaymentState = useSetRecoilState(paymentState);
    const setModalState = useSetRecoilState(modalState);
    const [formData, setFormData] = useState<IFinancesAddAmountModalFormData>({
        amount: 0
    });
    const [formErrors, setFormErrors] = useState<IFinancesAddAmountModalFormErrors>({})

    const onAmountChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const amount = parseFloat(value);
        setFormData({ amount });
        setPaymentState((state) => ({
            ...state,
            amount
        }))
    }

    const isFormDataValid = (): boolean => {
        const errors: IFinancesAddAmountModalFormErrors = {};

        if (formData.amount <= 0) {
            errors.amount = '"Необходимо заполнить «Сумма»."'
        }

        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    }

    const onSubmitButtonClick = () => {
        if (!isFormDataValid()) return;

        setModalState({
            type: ModalType.financesAdd,
            isOpened: true
        })
    }

    return (
        <Modal justifyContent="center">
            <div className="finances-add-amount-modal">
                <div className="finances-add-amount-modal__header">
                    <div className="finances-add-amount-modal__title">Пополнение баланса</div>
                </div>
                <div className="finances-add-amount-modal__body">
                    <TextInput
                        className="finances-add-amount-modal__input"
                        label="Сумма"
                        placeholder="Введите сумму для пополнения"
                        value={formData.amount}
                        hasError={!!formErrors?.amount}
                        errorMessage={formErrors?.amount}
                        onChange={onAmountChanged}
                    />

                    <Button
                        className="finances-add-amount-modal__button"
                        title="Пополнить"
                        onClick={onSubmitButtonClick}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default FinancesAddAmountModal;