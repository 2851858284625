import React from "react";
import "./styles/ClientsList.sass";
import Grid from "../../UI/Grid/Grid";
import { IGridDataCellProps, IGridHeaderCellProps } from "../../UI/Grid/types/Grid.types";
import { IClientsListProps } from "./types/ClientsList.types";
import { clientsDataCellFactory } from "./utils/factories";
import ClientsListCellName from "./components/ClientsListCellName/ClientsListCellName";
import { useSetRecoilState } from "recoil";
import { modalState, ModalType } from "../../../store/Modal.recoil";
import { IClientsListItem } from "../../../store/Clients.recoil";

const headers: IGridHeaderCellProps[] = [
    {
        title: 'Клиент'
    },
    {
        title: 'Показы'
    },
    {
        title: 'Досмотры'
    },
    {
        title: 'Клики'
    },
    {
        title: 'Потрачено'
    },
    {
        title: ''
    }
];

const ClientsList = ( props: IClientsListProps ) => {
    const { list } = props;
    const { count, page, limit, items, isFetching = false } = list;
    const flattedItems = items.map((item) => ({
        ...item,
        ...item.statistics
    }));

    const setModal = useSetRecoilState(modalState);

    const deleteClientAction = (item: IClientsListItem): void => {
        setModal({
            type: ModalType.deleteClient,
            options: { client: item },
            isOpened: true
        })
    }

    const data = flattedItems
        .reduce(( cells: IGridDataCellProps[], item: IClientsListItem ): IGridDataCellProps[] => {
            return [
                ...cells,
                ...clientsDataCellFactory(
                    item,
                    ['id', 'userId', 'companyId', 'statistics', 'inn'],
                    { name: <ClientsListCellName item={item}/> },
                    () => deleteClientAction(item),
                //     // () => {
                //     //     setModal({
                //     //         type: ModalType.releaseClient,
                //     //         options: { client: item },
                //     //         isOpened: true
                //     //     })
                //     // },
                )
            ];
        }, []);

    return (
        <div className="clients-list">
            <Grid
                className="clients-list__grid"
                headers={headers}
                data={data}
                page={page}
                limit={limit}
                count={count}
                isFetching={isFetching}
                columnsTemplate="3fr 1fr 1fr 1fr 1fr 64px"
            />
        </div>
    )
}

export default ClientsList;