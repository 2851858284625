import React, { BaseSyntheticEvent, useCallback } from 'react';
import './styles/Modal.sass';
import classNames from "classnames";
import { IModalProps } from "./types/Modal.types";
import { useRecoilState } from "recoil";
import { modalState } from "../../../store/Modal.recoil";

const Modal = (props: IModalProps) => {
    const [, setModal] = useRecoilState(modalState);

    const { className, children, justifyContent } = props;

    const getJustifyContentClassName = (justifyContent?: string): string => {
        switch (justifyContent) {
            case "left":
                return "modal__content-left";
            case "right":
                return "modal__content-right";
        }

        return "";
    }

    const closeModal = useCallback(() => {
        setModal({
            type: null,
            isOpened: false
        })
    }, [setModal]);

    const onCloseButtonClick = (): void => {
        closeModal();
    }

    const onBackdropClick = (e: BaseSyntheticEvent): void => {
        if (!e.target.classList.contains('modal__content')) {
            return;
        }

        closeModal();
    }

    return (
        <div className="modal">
            <div className="modal__backdrop" onClick={(e) => onBackdropClick(e)}>
                <div className={classNames("modal__content", className, getJustifyContentClassName(justifyContent))}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;