import React from "react";
import "./styles/CampaignViewDetails.sass";
import { ICampaignViewDetailsProps } from "./types/CampaignViewDetails.types";
import classNames from "classnames";
import { ICampaign } from "../../../../../api/services/campaigns/campaigns.response";
import { prettifyMoneyValue } from "../../../../../helpers/prettifier.helpers";
import { useRecoilValue } from "recoil";
import {
    getCampaignTargetingAges,
    getCampaignTargetingDevices,
    getCampaignTargetingFlatLocalities,
    getCampaignTargetingGenders,
    getCampaignTargetingGenres,
    getCampaignTargetingIntervals
} from "../../../../../store/Campaigns.recoil";

const CampaignViewDetails = ( props: ICampaignViewDetailsProps ) => {
    const { campaign, className } = props;

    const localities = useRecoilValue(getCampaignTargetingFlatLocalities);
    const intervals = useRecoilValue(getCampaignTargetingIntervals);
    const genres = useRecoilValue(getCampaignTargetingGenres);
    const devices = useRecoilValue(getCampaignTargetingDevices);
    const ages = useRecoilValue(getCampaignTargetingAges);
    const genders = useRecoilValue(getCampaignTargetingGenders);

    const getPeriod = ( campaign: ICampaign ): string => {
        let period = [];
        period.push(campaign.startsAt);

        if (campaign.endsAt) {
            period.push(campaign.endsAt);
        }

        return period.join(' - ');
    }

    return (
        <div className={classNames("campaign-view-details", className)}>
            <div className="campaign-view-details__brief">
                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Статус</div>
                    <div className="campaign-view-details__value">{campaign.statusLabel}</div>
                </div>

                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Период действия</div>
                    <div className="campaign-view-details__value">{getPeriod(campaign)}</div>
                </div>

                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Ссылка</div>
                    <div className="campaign-view-details__value">{campaign.targetLink}</div>
                </div>

                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Субъекты</div>
                    <div className="campaign-view-details__value">
                        {
                            localities.filter((item) => campaign.regions.includes(Number(item.value)))
                                .map((item, index) => (
                                    <span key={index}>{item.label}, </span>
                                ))
                        }
                    </div>
                </div>

                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Время показа</div>
                    <div className="campaign-view-details__value">
                        {
                            intervals
                                .filter((item) => campaign.intervals.includes(Number(item.value)))
                                .map((item, index) => (
                                    <span key={index}>{item.label}, </span>
                                ))
                        }
                    </div>
                </div>

                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Жанры телеканалов</div>
                    <div className="campaign-view-details__value">
                        { genres
                            .filter(( item ) => campaign.genres.includes(String(item.value)))
                            .map(( item, index ) => (
                                <span key={index}>{item.label}, </span>
                            ))
                        }
                    </div>
                </div>

                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Тип рекламы</div>
                    <div className="campaign-view-details__value">
                        { campaign.devices.map((device, index) => (
                            <span key={index}>{device === 'tv' ? 'Медийная реклама' : 'Performance реклама'},</span>
                        ))
                        }
                    </div>
                </div>

                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Возраст</div>
                    <div className="campaign-view-details__value">
                        { ages
                            .filter(( item ) => campaign.age.includes(Number(item.value)))
                            .map(( item, index ) => (
                                <span key={index}>{item.label}, </span>
                            ))
                        }
                    </div>
                </div>

                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Пол</div>
                    <div className="campaign-view-details__value">
                        { genders
                            .filter(( item ) => campaign.sex.includes(Number(item.value)))
                            .map(( item, index ) => (
                                <span key={index}>{item.label}, </span>
                            ))
                        }
                    </div>
                </div>

                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Бюджет в сутки</div>
                    <div className="campaign-view-details__value"
                         dangerouslySetInnerHTML={{ __html: prettifyMoneyValue(campaign.budgetDaily) }}
                    ></div>
                </div>

                <div className="campaign-view-details__row">
                    <div className="campaign-view-details__name">Бюджет на кампанию</div>
                    <div className="campaign-view-details__value"
                         dangerouslySetInnerHTML={{ __html: prettifyMoneyValue(campaign.budgetTotal) }}
                    ></div>
                </div>
            </div>
            <div className="campaign-view-details__video">
                <video controls playsInline>
                    <source src={campaign.content.url} type="video/mp4"/>
                </video>
            </div>
        </div>
    )
}

export default CampaignViewDetails;