import { atom, selector } from "recoil";

interface IClient {
    id: number;
    name: string;
}

interface IClientsState {
    currentClient?: IClient;
}

export const clientsState = atom<IClientsState>({
    key: "clientsState",
    default: {},
});

export const getClientsCurrentClient = selector<IClient | undefined>({
    key: 'clientsCurrentClient',
    get: ( { get } ) => {
        const { currentClient } = get(clientsState);
        return currentClient;
    },
})

export const getClientsIsLoggedToClient = selector<boolean>({
    key: 'clientsIsLoggedToClient',
    get: ( { get } ) => {
        const { currentClient } = get(clientsState);
        return currentClient !== undefined;
    },
})