import React from "react";
import "./styles/CampaignView.sass";
import { ICampaignViewProps } from "./types/CampaignView.types";
import CampaignViewDetails from "./components/CampaignViewDetails/CampaignViewDetails";
import CampaignViewStatistics from "./components/CampaignViewStatistics/CampaignViewStatistics";

const CampaignView = ( props: ICampaignViewProps ) => {
    const { campaign } = props;

    return (
        <div className="campaign-view">
            <CampaignViewDetails className="campaign-view__details" campaign={campaign} />
            <CampaignViewStatistics className="campaign-view__statistics" />
        </div>
    )
}

export default CampaignView;