import React from 'react';
import "./styles/Checkbox.sass";
import CheckboxCheckedIcon from "../SvgIcons/CheckboxCheckedIcon";
import CheckboxPartialCheckedIcon from "../SvgIcons/CheckboxPartialCheckedIcon";
import CheckboxUncheckedIcon from "../SvgIcons/CheckboxUncheckedIcon";
import { CheckboxState, ICheckboxProps } from "./types/Checkbox.types";
import classNames from "classnames";

const Checkbox = (props: ICheckboxProps) => {
    const { label, state, className, onClick } = props;

    const onCheckboxClick = () => {
        if (typeof onClick === "function") {
            onClick();
        }
    }

    return (
        <div className={classNames("checkbox", className) }>
            <button type="button" className="checkbox__icon" onClick={onCheckboxClick}>
                {state && state === CheckboxState.CHECKED && (<CheckboxCheckedIcon />)}
                {state && state === CheckboxState.PARTIAL_CHECKED && (<CheckboxPartialCheckedIcon />)}
                {(!state || state === CheckboxState.UNCHECKED) && (<CheckboxUncheckedIcon />)}
            </button>

            {label && (<div className="checkbox__label">{label}</div>)}
        </div>
    );
}

export default Checkbox;