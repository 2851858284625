import axios from "axios";
import campaignsAPI from "./services/campaigns/campaigns.api";
import companiesAPI from "./services/companies/companies.api";
import clientAPI from "./services/clients/clients.api";
import paymentsAPI from "./services/payments/payments.api";
import profileAPI from "./services/profileAPI";
import suggestionAPI from "./services/suggestionAPI";
import usersAPI from "./services/users/users.api";

const BASE_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';

class APIService {
    campaigns = campaignsAPI;
    companies = companiesAPI;
    clients = clientAPI;
    payments = paymentsAPI;
    profile = profileAPI;
    suggestion = suggestionAPI;
    users = usersAPI;

    setToken = (token: string) => {
        axios.defaults.headers['Access-Token'] = `${token}`;
    };

    clearToken = () => {
        delete axios.defaults.headers['Access-Token'];
    };
}

const API = new APIService();
export default API;