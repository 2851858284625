import React, { useEffect, useState } from 'react';
import Grid from "../../UI/Grid/Grid";
import {
    IGridCollection,
    IGridDataCellProps,
    IGridHeaderCellProps
} from "../../UI/Grid/types/Grid.types";

import "./styles/FinancesHistory.sass";
import { IFinancesHistoryProps } from "./types/FinancesHistory.types";
import classNames from "classnames";
import API from "../../../api";
import { financesHistoryDataCellFactory } from "./utils/factories";
import { prettifyMoneyValue } from "../../../helpers/prettifier.helpers";

const headers: IGridHeaderCellProps[] = [
    {
        column: 'title',
        title: 'Номер'
    },
    {
        column: 'status',
        title: 'Статус'
    },
    {
        column: 'amount',
        title: 'Сумма'
    },
    {
        column: 'date',
        title: 'Дата'
    },
];

const FinancesHistory = (props: IFinancesHistoryProps) => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [grid, setGrid] = useState<IGridCollection>({
        count: 25,
        page: 1,
        limit: 25,
        items: []
    });
    const [isFetching, setIsFetching] = useState<boolean>(false);

    useEffect(() => {
        setIsFetching(true);

        API.payments.list()
            .then(( response ) => {
                setIsFetching(false);
                const { data } = response;
                const { credit, deposit } = data;

                const depositItems = deposit.reduce((cells: IGridDataCellProps[], item: any) => {
                    return [
                        ...cells,
                        ...financesHistoryDataCellFactory(
                            item,
                            {
                                amount: (<div dangerouslySetInnerHTML={{__html: prettifyMoneyValue(item.amount) }}></div>)
                            }
                        )
                    ];
                }, []);

                const creditItems = credit.reduce((cells: IGridDataCellProps[], item: any) => {
                    return [
                        ...cells,
                        ...financesHistoryDataCellFactory(
                            item,
                            {
                                amount: (<div dangerouslySetInnerHTML={{__html: prettifyMoneyValue(item.amount) }}></div>)
                            }
                        )
                    ];
                }, []);

                if (activeTab === 0) {
                    setGrid((state) => ({
                        ...state,
                        count: deposit.length,
                        limit: deposit.length,
                        items: depositItems
                    }))
                } else {
                    setGrid((state) => ({
                        ...state,
                        count: credit.length,
                        limit: credit.length,
                        items: creditItems
                    }))
                }
            })
    }, [activeTab]);

    return (
        <>
            <div className="finances-history">
                <div className="finances-history__switch">
                    <button
                        className={classNames("finances-history__button", { 'active': activeTab === 0 })}
                        type="button"
                        onClick={() => {setActiveTab(0)}}
                    >
                        Пополнения
                    </button>

                    <button
                        className={classNames("finances-history__button", { 'active': activeTab === 1 })}
                        type="button"
                        onClick={() => {setActiveTab(1)}}
                    >
                        Списания
                    </button>
                </div>

                {grid.items.length > 0 && (
                    <Grid
                        className="finances-history__grid"
                        columnsTemplate="repeat(4, 1fr)"
                        headers={headers}
                        data={grid.items}
                        page={grid.page}
                        limit={grid.limit}
                        count={grid.count}
                        isFetching={isFetching}
                    />
                )}
            </div>

            {grid.items.length === 0 && activeTab === 0 && (
                <div className="finances-history__empty">В данный момент пополнений нет</div>
            )}

            {grid.items.length === 0 && activeTab === 1 && (
                <div className="finances-history__empty">В данный момент списаний нет</div>
            )}
        </>
    );
}

export default FinancesHistory;
