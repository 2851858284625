import React from 'react';
import './styles/CampaignsListItem.sass';
import Button from "../../UI/Button/Button";
import { useNavigate } from "react-router-dom";
import { ICampaignsListItemProps } from "./types/CampaignsListItem.types";

const CampaignsListItem = (props: ICampaignsListItemProps) => {
    const { item } = props;

    const navigate = useNavigate();

    const onGoToButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        return navigate(`/campaigns/${item.id}/view`);
    }

    return (
        <div className="campaigns-list-item">
            <div className="campaigns-list-item__left">
                <div className="campaigns-list-item__image">
                    <video controls playsInline>
                        <source src={item.content.url} type="video/mp4" />
                    </video>
                </div>
            </div>
            <div className="campaigns-list-item__right">
                <div className="campaigns-list-item__title">{item.name}</div>
                <div className="campaigns-list-item__description">
                    <div className="campaigns-list-item__stat">Показов всего: <strong>{item.statistics.total}</strong></div>
                    <div className="campaigns-list-item__stat">Показов сегодня: <strong>{item.statistics.today}</strong></div>
                    <div className="campaigns-list-item__stat">Статус: <strong>{item.status}</strong></div>
                </div>
                <Button
                    isSecondary
                    className="campaigns-list-item__btn"
                    title="Подробнее"
                    onClick={onGoToButtonClick}
                />
            </div>
        </div>
    )
}

export default CampaignsListItem;