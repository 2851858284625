import React from "react";
import { ISvgIconProps } from "./types/SvgIcon.types";

const ErrorIcon = (props: ISvgIconProps) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 2.66663C8.65335 2.66663 2.66669 8.65329 2.66669 16C2.66669 23.3466 8.65335 29.3333 16 29.3333C23.3467 29.3333 29.3334 23.3466 29.3334 16C29.3334 8.65329 23.3467 2.66663 16 2.66663ZM15 10.6666C15 10.12 15.4534 9.66663 16 9.66663C16.5467 9.66663 17 10.12 17 10.6666V17.3333C17 17.88 16.5467 18.3333 16 18.3333C15.4534 18.3333 15 17.88 15 17.3333V10.6666ZM17.2267 21.84C17.16 22.0133 17.0667 22.1466 16.9467 22.28C16.8134 22.4 16.6667 22.4933 16.5067 22.56C16.3467 22.6266 16.1734 22.6666 16 22.6666C15.8267 22.6666 15.6534 22.6266 15.4934 22.56C15.3334 22.4933 15.1867 22.4 15.0534 22.28C14.9334 22.1466 14.84 22.0133 14.7734 21.84C14.7067 21.68 14.6667 21.5066 14.6667 21.3333C14.6667 21.16 14.7067 20.9866 14.7734 20.8266C14.84 20.6666 14.9334 20.52 15.0534 20.3866C15.1867 20.2666 15.3334 20.1733 15.4934 20.1066C15.8134 19.9733 16.1867 19.9733 16.5067 20.1066C16.6667 20.1733 16.8134 20.2666 16.9467 20.3866C17.0667 20.52 17.16 20.6666 17.2267 20.8266C17.2934 20.9866 17.3334 21.16 17.3334 21.3333C17.3334 21.5066 17.2934 21.68 17.2267 21.84Z"
                fill="#EF5350"/>
        </svg>
    );
};

export default ErrorIcon;
