import React from 'react';
import './styles/ClientsListEmpty.sass';
import Button from "../../../../UI/Button/Button";
import { getIsCreateClientModalOpened, modalState, ModalType } from "../../../../../store/Modal.recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

const ClientsListEmpty = () => {
    const setModalState = useSetRecoilState(modalState);
    const isCreateClientModalOpened = useRecoilValue(getIsCreateClientModalOpened);

    const onButtonClick = (): void => {
        setModalState({
            type: ModalType.createClient,
            isOpened: !isCreateClientModalOpened,
        })
    }

    return (
        <div className="clients-list-empty">
            <p className="clients-list-empty__text">У вас пока нет добавленных клиентов</p>
            <Button title="Создать кабинет клиента" className="clients-list-empty__button" onClick={onButtonClick} />
        </div>
    )
}

export default ClientsListEmpty;