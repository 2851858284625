import React, { useEffect, useState } from 'react';

import './App.sass';

import { Outlet } from "react-router-dom";

import Header from "components/Header/Header";
import Sidebar from "components/Sidebar/Sidebar";
import ProfileSidebar from "components/Profile/ProfileSidebar/ProfileSidebar";
import FinancesAddModal from "./components/Finances/FinancesAddModal/FinancesAddModal";
import ClientsCreateModal from "./components/Client/ClientsCreateModal/ClientsCreateModal";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    getIsProfileModalOpened,
    getIsFinancesAddModalOpened,
    getIsFinancesAddAmountModalOpened,
    getIsCreateClientModalOpened,
    getIsDeleteClientModalOpened,
    getIsReleaseClientModalOpened,
    getIsTransferClientModalOpened,
    getIsCampaignAdvertiseMarkModalOpened,
    getIsSupportModalOpened, getIsFinancesRedistributionModalOpened,
} from "./store/Modal.recoil";
import { companyState } from "./store/Company.recoil";
import { toastState } from "./store/Toast.recoil";
import Toast from "./components/UI/Toast/Toast";
import ClientsDeleteModal from "./components/Client/ClientsDeleteModal/ClientsDeleteModal";
import ClientsReleaseModal from "./components/Client/ClientsReleaseModal/ClientsReleaseModal";
import ClientsTransferModal from "./components/Client/ClientsTransferModal/ClientsTransferModal";
import { userState } from "./store/User.recoil";
import CampaignAdvertiserMarkModal from "./components/Campaign/CampaignAdvertiserMarkModal/CampaignAdvertiserMarkModal";
import SupportModal from "./components/SupportModal/SupportModal";
import { profileCompanyFactory, profilePersonFactory, profileState } from "./store/Profile.recoil";
import API from "./api";
import FinancesAddAmountModal from "./components/Finances/FinancesAddAmountModal/FinancesAddAmountModal";
import FinancesRedistributionModal from "./components/Finances/FinancesRedistributionModal/FinancesRedistributionModal";
import MobileMenu from "./components/MobileMenu/MobileMenu";
import classNames from "classnames";

const App = () => {
    const isProfileModalOpened = useRecoilValue(getIsProfileModalOpened);
    const isFinancesAddModalOpened = useRecoilValue(getIsFinancesAddModalOpened);
    const isFinancesAddAmountModalOpened = useRecoilValue(getIsFinancesAddAmountModalOpened);
    const isFinancesRedistributionModalOpened = useRecoilValue(getIsFinancesRedistributionModalOpened);
    const isCreateClientModalOpened = useRecoilValue(getIsCreateClientModalOpened);
    const isDeleteClientModalOpened = useRecoilValue(getIsDeleteClientModalOpened);
    const isReleaseClientModalOpened = useRecoilValue(getIsReleaseClientModalOpened);
    const isTransferClientModalOpened = useRecoilValue(getIsTransferClientModalOpened);
    const isCampaignAdvertiseMarkModalOpened = useRecoilValue(getIsCampaignAdvertiseMarkModalOpened);
    const isSupportModalOpened = useRecoilValue(getIsSupportModalOpened);

    const toast = useRecoilValue(toastState);

    const setUserState = useSetRecoilState(userState);
    const setCompanyState = useSetRecoilState(companyState);
    const setProfileState = useSetRecoilState(profileState);

    const [isMobileMenuOpened, setIsMobileMenuOpened] = useState<boolean>(false);

    useEffect(() => {
        Promise.all([
            API.users.info(),
            API.companies.info()
        ]).then(([userResponse, companyResponse]) => {
            const { data: user } = userResponse;
            const { data: company } = companyResponse;

            setUserState(user);
            setCompanyState(company);

            setProfileState((state) => {
                return {
                    ...state,
                    person: profilePersonFactory(user),
                    company: profileCompanyFactory(company),
                }
            });
        })
    }, [setUserState, setCompanyState, setProfileState]);

    const onBurgerButtonToggle = (): void => {
        setIsMobileMenuOpened(!isMobileMenuOpened);
    }

    return (
        <div className={classNames("app", { "is-mobile-menu-opened": isMobileMenuOpened })}>
            <Header onBurgerButtonToggle={onBurgerButtonToggle} />

            <MobileMenu />

            <div className="main">
                <Sidebar />

                <div className="content">
                    <Outlet />
                </div>
            </div>

            {isProfileModalOpened && (<ProfileSidebar />)}
            {isFinancesAddModalOpened && (<FinancesAddModal />)}
            {isFinancesAddAmountModalOpened && (<FinancesAddAmountModal />)}
            {isFinancesRedistributionModalOpened && (<FinancesRedistributionModal />)}
            {isCreateClientModalOpened && (<ClientsCreateModal />)}
            {isDeleteClientModalOpened && (<ClientsDeleteModal />)}
            {isReleaseClientModalOpened && (<ClientsReleaseModal />)}
            {isTransferClientModalOpened && (<ClientsTransferModal />)}
            {isCampaignAdvertiseMarkModalOpened && (<CampaignAdvertiserMarkModal />)}
            {isSupportModalOpened && (<SupportModal />)}


            {toast.isShown && (<Toast type={toast.type} message={toast.message} />)}
        </div>
    );
}

export default App;
