import React from "react";
import { ISvgIconProps } from "./types/SvgIcon.types";

const SuccessIcon = (props: ISvgIconProps) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 2.66663C8.65335 2.66663 2.66669 8.65329 2.66669 16C2.66669 23.3466 8.65335 29.3333 16 29.3333C23.3467 29.3333 29.3334 23.3466 29.3334 16C29.3334 8.65329 23.3467 2.66663 16 2.66663ZM22.3734 12.9333L14.8134 20.4933C14.6267 20.68 14.3734 20.7866 14.1067 20.7866C13.84 20.7866 13.5867 20.68 13.4 20.4933L9.62669 16.72C9.24002 16.3333 9.24002 15.6933 9.62669 15.3066C10.0134 14.92 10.6534 14.92 11.04 15.3066L14.1067 18.3733L20.96 11.52C21.3467 11.1333 21.9867 11.1333 22.3734 11.52C22.76 11.9066 22.76 12.5333 22.3734 12.9333Z"
                fill="#2AC64E"/>
        </svg>
    );
};

export default SuccessIcon;
